import * as React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import './style.css'
import { Popper, TextField } from '@mui/material';







const MyAutocomplete = ({ data, name, label, onChange = () => { }, defaultValue, disabled = false, isMultiple = false, onClick = () => { } }) => {

    const CustomPopper = (props) => {
        return (<Popper {...props}
            placement="bottom" sx={{ height: "70px", }} style={{ width: props.anchorEl.clientWidth, height: "70px" }} />);
    }

    return (
        <div>
            {label && <label className="input-label" htmlFor={name}>{label}</label>}
            {isMultiple ?
                <Autocomplete
                    style={{
                        // Seleziona l'input dell'Autocomplete
                        borderRadius: '20px',
                        outline: 'none',
                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        padding: '10px',
                        height: '50px'


                    }}
                    onOpen={onClick}
                    PopperComponent={CustomPopper}
                    multiple
                    id="tags-standard"
                    onChange={onChange}
                    isOptionEqualToValue={(option, value) => option.id === value.id && option.value === value.value}
                    getOptionLabel={(option) => (option && option.value) || ''}
                    options={data}
                    value={defaultValue}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder={label}
                        />
                    )}
                />
                : <Autocomplete
                    id="asynchronous-demo"
                    sx={{ boxShadow: ' rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' }}
                    onChange={onChange}
                    onOpen={onClick}
                    style={{
                        // Seleziona l'input dell'Autocomplete
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '20px',
                        outline: 'none',
                        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset',
                        height: '50px'             // Aggiungi eventuali altri stili CSS qui

                    }}
                    //   }}
                    value={defaultValue}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => (option && option.value) || ''}
                    options={data}
                    disabled={disabled}
                    renderInput={(params) => (
                        <TextField
                            placeholder={label}
                            {...params}
                            name={name}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>

                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />}
        </div>
    );
}

export default MyAutocomplete;