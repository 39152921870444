import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { useRoleDesc } from "../../../utils/useRoleDesc";
import {
  Typography,
  ListItemIcon,
  ListItemText,
  Icon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ArchiveOutlined,
  HomeOutlined,
  BookOutlined,
} from "@material-ui/icons";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  RESET_ARCHIVE_CONFIG_ID,
  SET_COPY_MODE,
  SET_EDIT_MODE,
} from "../../../store/actions/archive/actions";
import {
  RESET_COMPLETE_CONFIG,
  SET_CONFIG_CURRENT_SECTION,
  RESET_PRODUCT_SELECED,
} from "../../../store/actions/actions";
import NavLogo from "../NavLogo/NavLogo.jsx";
import NavMenuLogo from "../NavMenuLogo/NavMenuLogo.jsx";
import { useMqXS } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import RequestAssistanceForm from "../RequestAssistanceForm/RequestAssistanceForm";
import { RateReviewOutlined } from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  weight600: {
    fontWeight: 600,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  appBar: {
    backgroundColor: "#fff",
    zIndex: theme.zIndex.drawer + 1,
    borderBottom: `1px solid #e5e5e5`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(1),
    },
    color: theme.palette.primary.main,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  listItem: {
    fontFamily: theme.fontMontserrat,
    fontWeight: 600,
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  menuIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  selected: {
    borderRight: `4px solid ${theme.palette.secondary.main}`,
    fontWeight: 600,
    backgroundColor: `${theme.palette.secondary.light} !important`,
  },
  listItemText: {
    fontFamily: theme.fontMontserrat,
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
}));

const NavMenu = (props) => {
  const { window } = props;
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const mq_xs = useMqXS();

  const resetArchive = () => dispatch({ type: RESET_ARCHIVE_CONFIG_ID });
  const resetConfig = () => dispatch({ type: RESET_COMPLETE_CONFIG });
  const setEditMode = (objData) =>
    dispatch({ type: SET_EDIT_MODE, value: objData.value });
  const setCopyMode = (objData) =>
    dispatch({ type: SET_COPY_MODE, value: objData.value });

  const is_authenticated = useSelector((state) => {
    return state.application.is_authenticated;
  });

  const whichRole = useRoleDesc();

  console.log(whichRole);

  const drawerList = [
    {
      path: "/home",
      icon: <HomeOutlined color="primary" />,
      text: "Home",
    },
    {
      path: "/configuration",
      icon: (
        <Icon color="primary">
          <i className="material-icons-outlined">build_circle</i>
        </Icon>
      ),
      text: "Configuration",
      onClick: () => {
        handleClickOpenSection("/configuration");
      },
    },
    {
      path: "/archive",
      icon: <ArchiveOutlined color="primary" />,
      text: "Archive",
    },
    {
      icon: <RateReviewOutlined color="primary" />,
      text: "Contact AKonfig Team",
      onClick: () => {
        setOpenModal(true);
      },
    },
  ];

  if (
    (is_authenticated && whichRole === "External") ||
    (is_authenticated && whichRole === "Admin")
  ) {
    drawerList.push({
      path: "/tutorial",
      icon: <BookOutlined color="primary" />,
      text: "Tutorials",
    });
  }

  const checkPath = () => {
    if (history.location.pathname.indexOf("home") > -1) {
      return "/home";
    }

    if (history.location.pathname.indexOf("configuration") > -1) {
      return "/configuration";
    }

    if (history.location.pathname.indexOf("archive") > -1) {
      return "/archive";
    }
    if (history.location.pathname.indexOf("tutorial") > -1) {
      return "/tutorial";
    }

    return history.location.pathname;
  };

  const [open, setOpen] = useState(false);

  const [openModal, setOpenModal] = React.useState(false);

  const handleClickOpenSection = (path) => {
    resetArchive();
    resetConfig();
    setEditMode({ value: false });
    setCopyMode({ value: false });
    if (path === "/configuration") {
      dispatch({ type: SET_CONFIG_CURRENT_SECTION, value: 0 });
      dispatch({ type: RESET_PRODUCT_SELECED });
    }

    history.push(path);
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
    setOpenModal(false);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={styles.root}>
      <AppBar position="fixed" classes={{ root: styles.appBar }} elevation={0}>
        <Toolbar>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              classes={{ root: styles.menuButton }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              color="primary"
              variant={mq_xs ? "h6" : "h5"}
              style={{ fontSize: mq_xs ? '13px' : '' }}
              classes={{ root: styles.weight600 }}
            >
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                AKonfig
              </Link>
            </Typography>

          </div>

          <NavLogo />
        </Toolbar>
        <p style={{ color: '#002b49', fontSize: mq_xs ? '11px' : '16px', margin: '0', position: 'absolute', top: '50%', left: mq_xs ? '60%' : '50%', transform: 'translate(-50%, -50%)' }}>Price list updated on 01/01/2025</p>


      </AppBar>



      <nav className={styles.drawer} aria-label="Akonfig menu">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={handleDrawerToggle}
          classes={{
            paper: styles.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Toolbar>
            <Typography
              color="primary"
              variant="h5"
              classes={{ root: styles.weight600 }}
            >
              AKonfig
            </Typography>
          </Toolbar>
          {is_authenticated ? (
            <List>
              {drawerList.map((e, index) => {
                return (
                  <ListItem
                    classes={{
                      root: styles.listItem,
                      selected: styles.selected,
                    }}
                    selected={checkPath() === e.path ? true : false}
                    button
                    onClick={
                      e.onClick
                        ? e.onClick
                        : () => handleClickOpenSection(e.path)
                    }
                    key={index}
                  >
                    <ListItemIcon>{e.icon}</ListItemIcon>
                    <ListItemText
                      disableTypography
                      classes={{ root: styles.listItemText }}
                      primary={e.text}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : null}
          {/* <Button className={styles.menuButton} onClick={() => { setOpenModal(true) }}>Contact Akonfig Team</Button> */}
          <NavMenuLogo />
          <RequestAssistanceForm
            openModal={openModal}
            handleClose={() => {
              setOpenModal(false);
            }}
          />
        </Drawer>
      </nav>
    </div>
  );
};

export default NavMenu;
