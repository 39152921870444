import * as actions from "../actions/actions";

const initialState = {
	specs: sessionStorage.getItem("specs") ? JSON.parse(sessionStorage.specs) : null,
	specialSelected: sessionStorage.getItem("specialSelected") ? JSON.parse(sessionStorage.specialSelected) : null,
	accessories: sessionStorage.getItem("accessories") ? JSON.parse(sessionStorage.accessories) : null,
	id: null,
	name: null,
	productsList: sessionStorage.getItem("productList") ? JSON.parse(sessionStorage.productList) : null,
	product: sessionStorage.getItem("productSelected") ? JSON.parse(sessionStorage.productSelected) : {
		id: null,
		family: null,
		product_name: null,
		product_desc: null,
		rdre: null,
		size: null,
		slash_after_position: null,
		dash_after_position: null,
		matclass: null,
		base_price: 0,
		special_request: null,
		size_list: null
	},
	specsList: sessionStorage.getItem("specsList") ? JSON.parse(sessionStorage.specsList) : [],
	specsSelected: sessionStorage.getItem("specsSelected") ? JSON.parse(sessionStorage.specsSelected) : [],
	accessoriesList: sessionStorage.getItem("accessoriesList") ? JSON.parse(sessionStorage.accessoriesList) : [],
	accessoriesSelected: sessionStorage.getItem("accessoriesSelectedList") ? JSON.parse(sessionStorage.accessoriesSelectedList) : [],
	finalPrice: null,
	finalNetPrice: null,
	discount: sessionStorage.getItem("discount") ? JSON.parse(sessionStorage.discount) : {
		// authorization: 0,
		// is_military: false,
		// is_aerospace: false,
		// is_nuclear: false,
		// existing_code: null,
		// min_order_qty: null,
		// est_annual_qty: null,
		// customersList: [],
		// id_customer: null,
		// customer: null,
		// manualDiscountClassList: [],
		// manual_discount_class: null,
		// customerTypeList: [],
		// id_customer_type: null,
		// customerDirectList: [
		// 	{ id: 1, text: "Direct" },
		// 	{ id: 2, text: "Indirect" }
		// ],
		// customer_direct_flag: null,
		// customer_type: null,
		// salesEngineersList: [],
		// id_sales_engineer: null,
		// sales_engineer: null,
		// branchManagersList: [],
		// id_branch_manager: null,
		// branch_manager: null,
		// cluster_manager_limit_discount: 0,
		// cluster_manager_limit_price: 0,
		// customer_grid_discount: 0,
		// customer_grid_price: 0,
		// discount_perc_rdre: 0,
		// margin_perc_country_unit: 0,
		// product_gross_price: 0,
		// product_manager_limit_discount: 0,
		// product_manager_limit_price: 0,
		// sales_engineer_limit_discount: 0,
		// sales_engineer_limit_price: 0,
		// top_grid_discount: 0,
		// top_grid_price: 0,
		// top_sales_engineer_limit_discount: 0,
		// top_sales_engineer_limit_price: 0,
		// sales_net_price: "",
		// sales_manual_discount: 0,
		// final_discount: 0,
		// final_discount_cluster: 0,
		// final_discount_grid: 0,
		// extra_discount: 0,
		// extra_discounted_price: 0,
		// extra_final_discount: 0,
		// extra_final_discount_grid: 0,
		// extra_final_discount_customer: 0,
		// internal_sales_limit_discount: 0,
		// internal_sales_limit_price: 0,
		// sales_manager_limit_discount: 0,
		// sales_manager_limit_price: 0,
		// general_manager_limit_discount: 0,
		// general_manager_limit_price: 0,
		// margin_it: 0,
		// factor: 0,
		// sdd: 0,
		// grid_cost: 0,
		// tp_calc: 0,
		// markup: 0,
		// p_min: 0,
		// margin_perc: 0,
		// projectsList: [],
		// id_project: null,
		// project: null
	},
	discountInfo: sessionStorage.getItem("discountInfo") ? JSON.parse(sessionStorage.discountInfo) : null,
	discountPrices: sessionStorage.getItem("discountPrices") ? JSON.parse(sessionStorage.discountPrices) : {},
	calculatedDiscount: sessionStorage.getItem("calculatedDiscount") ? JSON.parse(sessionStorage.calculatedDiscount) : {},
	applicationInfo: sessionStorage.getItem("applicationInfo") ? JSON.parse(sessionStorage.applicationInfo) : {},
	archiveInfo: sessionStorage.getItem("archiveInfo") ? JSON.parse(sessionStorage.archiveInfo) : null,
	customCustomer: sessionStorage.getItem("customCustomer") ? JSON.parse(sessionStorage.customCustomer) : null,
	existingCode: (sessionStorage.getItem("existingCode") || '').replace(/"/g, '') || '',
	schedeTaratura: sessionStorage.getItem("schedeTaratura") ? JSON.parse(sessionStorage.schedeTaratura) : null,

};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SET_EXISTING_CODE:
			sessionStorage.setItem("existingCode", JSON.stringify(action.value));
			return {
				...state,
				existingCode: action.value,
			};
		case actions.SET_ARCHIVE_INFO:
			sessionStorage.setItem("archiveInfo", JSON.stringify(action.value));
			return {
				...state,
				archiveInfo: action.value,
			};
		case actions.RESET_ARCHIVE_INFO:
			sessionStorage.setItem("archiveInfo", JSON.stringify(null));
			return {
				...state,
				archiveInfo: null, // Resetta il campo special request a una stringa vuota
			};
		case actions.SET_SPECIAL_REQUEST:
			sessionStorage.setItem("specialSelected", JSON.stringify(action.value));
			return {
				...state,
				specialSelected: action.value,
			};
		case actions.RESET_SPECIAL_REQUEST:
			sessionStorage.setItem("specialSelected", JSON.stringify(null));
			return {
				...state,
				specialSelected: null, // Resetta il campo special request a una stringa vuota
			};
		case actions.SET_DISCOUNT:
			sessionStorage.setItem("discount", JSON.stringify(action.value));
			return {
				...state,
				discount: action.value
			}
		case actions.SET_DISCOUNT_INFO:

			sessionStorage.setItem("discountInfo", JSON.stringify(action.value));
			return {
				...state,
				discountInfo: action.value
			}
		case actions.SET_APPLICATION_INFO:
			const updatedApplicationInfo = {
				...state.applicationInfo,
				[action.key]: action.value
			};

			const newState = {
				...state,
				applicationInfo: updatedApplicationInfo
			};
			sessionStorage.setItem("applicationInfo", JSON.stringify(updatedApplicationInfo));
			return newState
		case actions.SET_APPLICATION_INFO_FINAL:
			sessionStorage.setItem("applicationInfo", JSON.stringify(action.value));
			return {
				...state,
				applicationInfo: action.value
			}
			sessionStorage.setItem("applicationInfo", JSON.stringify(updatedApplicationInfo));
			return newState
		case actions.SET_DISCOUNT_PRICES:
			sessionStorage.setItem("discountPrices", JSON.stringify(action.value));
			//metti le informazioni dentro discount 
			return {
				...state,
				discountPrices: action.value
			}
		case actions.SET_SCHEDE_TARATURA:
			sessionStorage.setItem("schedeTaratura", JSON.stringify(action.value));
			//metti le informazioni dentro discount 
			return {
				...state,
				schedeTaratura: action.value
			}
		case actions.CLEAR_SCHEDE_TARATURA:
			sessionStorage.setItem("schedeTaratura", null);
			//metti le informazioni dentro discount 
			return {
				...state,
				schedeTaratura: null
			}
		case actions.SET_CALCULATED_DISCOUNT:
			sessionStorage.setItem("calculatedDiscount", JSON.stringify(action.value));
			//metti le informazioni dentro discount 
			return {
				...state,
				calculatedDiscount: action.value
			}
		case actions.SET_SPECS:
			sessionStorage.setItem("specs", JSON.stringify(action.value));
			return {
				...state,
				specs: action.value
			}
		case actions.SET_ACCS:
			sessionStorage.setItem("accessories", JSON.stringify(action.value));
			return {
				...state,
				accessories: action.value
			}
		case actions.SET_PRODUCTS_LIST:
			sessionStorage.setItem("productList", JSON.stringify(action.value));
			return {
				...state,
				productsList: action.value
			}
		case actions.SET_ARRAY:
			sessionStorage.setItem([action.fieldName], JSON.stringify(action.value));
			return { ...state, [action.fieldName]: action.value };
		case actions.SET_BASE_PRICE:
			sessionStorage.setItem("productSelected", JSON.stringify(
				{
					...state.product,
					base_price: action.value
				}
			));
			return {
				...state,
				product: {
					...state.product,
					base_price: action.value
				}
			}
		case actions.SET_FINAL_PRICE:
			return {
				...state,
				finalPrice: action.value
			}
		case actions.SET_FINAL_NET_PRICE:
			return {
				...state,
				finalNetPrice: action.value
			}
		case actions.RESET_SPECS_LIST:

			return {
				...state,
				specsList: [],
				specsSelected: []
			}
		case actions.RESET_ACC_LIST:
			return {
				...state,
				accessoriesList: [],
				accessoriesSelected: []
			}
		case actions.RESET_PRODUCT_SELECED:

			sessionStorage.setItem("productSelected", JSON.stringify({
				id: null,
				family: null,
				product_name: null,
				product_desc: null,
				rdre: null,
				size: null,
				slash_after_position: null,
				dash_after_position: null,
				matclass: null,
				base_price: 0,
				special_request: null,
				size_list: null
			}));
			return {
				...state,
				product: {
					id: null,
					family: null,
					product_name: null,
					product_desc: null,
					rdre: null,
					size: null,
					slash_after_position: null,
					dash_after_position: null,
					matclass: null,
					base_price: 0,
					special_request: null,
					size_list: null
				}
			}
		case actions.UPDATE_PRODUCT_SELECTED:
			sessionStorage.setItem("productSelected", JSON.stringify({
				...state.product,
				rdre: action.value.rdre,
				size: action.value.size,
				family: action.value.family,
				slash_after_position: action.value.slash_after_position,
				dash_after_position: action.value.dash_after_position,
				matclass: action.value.matclass,
				product_desc: action.value.product_desc,
				product_name: action.value.product || action.value.product_name,
				operating_mode: action.value.operating_mode,
				series: action.value.series,
				size_list: action.value.size_list
			}));
			return {
				...state,
				product: {
					...state.product,
					rdre: action.value.rdre,
					size: action.value.size,
					family: action.value.family,
					slash_after_position: action.value.slash_after_position,
					dash_after_position: action.value.dash_after_position,
					matclass: action.value.matclass,
					product_desc: action.value.product_desc,
					product_name: action.value.product || action.value.product_name,
					operating_mode: action.value.operating_mode,
					series: action.value.series,
					size_list: action.value.size_list
				}
			}
		case actions.UPDATE_SPEC_SELECTED:


			const objDataSpecs = {
				id_datasheet: action.id_datasheet,
				value: action.value,
				position: action.position,
				type: action.optionType,
				value_desc: action.value_desc,
				value_details: action.value_details,
				in_datasheet: action.in_datasheet,
				is_special: action.is_special,
				is_valid: action.is_valid,
				price: action.price,
				is_gross: action.is_gross,
				accessoriesControlled: action.accessoriesControlled
			}

			sessionStorage.setItem("specsSelected", JSON.stringify(
				state.specsSelected.map(s =>
					s.position === action.position // se la posizione che sto ciclando è uguale alla posizione che sto scegliendo
						? {
							...s, // posizione dello store da aggiornare
							...objDataSpecs// dati nuovi 
						}
						: s
				)
			));

			/**
			 * prendo lo stato generale e gli dico che voglio aggiornare specsSelected
			 * specsSelected diventa un array di oggetti che deve essere uguale a se stesso + la nuova posizione aon i dati aggiornati
			 * 
			 */

			return {
				...state,
				specsSelected: state.specsSelected.map(s =>
					s.position === action.position // se la posizione che sto ciclando è uguale alla posizione che sto scegliendo
						? {
							...s, // posizione dello store da aggiornare
							...objDataSpecs// dati nuovi 
						}
						: s
				)
			}
		case actions.SET_ACCESSORIES_LIST:
			sessionStorage.setItem("accessoriesList", JSON.stringify(action.value));
			return {
				...state,
				accessoriesList: action.value
			}
		case actions.SET_ACCESSORIES_SELECTED_LIST:
			sessionStorage.setItem("accessoriesSelectedList", JSON.stringify(action.value));
			return {
				...state,
				accessoriesSelected: action.value
			}
		case actions.UPDATE_ACCESSORY_SELECTED:
			sessionStorage.setItem("accessoriesSelectedList", JSON.stringify(state.accessoriesSelected.map((a) => {
				if (a.family === action.family) {
					return {
						...a,
						accessory: action.accessory,
						value: action.value,
						desc: action.desc,
						price: action.price,
						is_gross: action.is_gross
					};
				} else {
					return a;
				}
			})));

			sessionStorage.setItem("accessoriesList", JSON.stringify(state.accessoriesList.map((a) => {
				if (a.family === action.family) {
					return {
						...a,
						accessory: action.accessory,
						value: action.value,
						desc: action.desc,
						price: action.price,
						is_gross: action.is_gross
					};
				} else {
					return a;
				}
			})));





			return {
				...state,
				accessoriesSelected: state.accessoriesSelected.map((a) => {
					if (a.family === action.family) {
						return {
							...a,
							accessory: action.accessory,
							value: action.value,
							desc: action.desc,
							price: action.price,
							is_gross: action.is_gross
						};
					} else {
						return a;
					}
				}),
				accessoriesList: state.accessoriesList.map((a) => {
					if (a.family === action.family) {
						return {
							...a,
							accessory: action.accessory,
							value: action.value,
							desc: action.desc,
							price: action.price,
							is_gross: action.is_gross
						};
					} else {
						return a;
					}
				})
			}
		case actions.RESET_ACCESSORIES_SELECTED:
			return {
				...state,
				accessoriesSelected: state.accessoriesSelected.map((a) => {
					if (a.family === action.family) {
						return {
							...a,
							value: null,
							desc: null,
						};
					} else {
						return a;
					}
				})
			}
		case actions.RESET_COMPLETE_CONFIG:
			sessionStorage.setItem("productSelected", null)
			sessionStorage.setItem("specs", null)
			sessionStorage.setItem("accessories", null)
			sessionStorage.setItem("discount", null)
			sessionStorage.setItem("discountInfo", null)
			sessionStorage.setItem("calculatedDiscount", null)
			sessionStorage.setItem("applicationInfo", null)
			sessionStorage.setItem("schedeTaratura", null)
			return {
				...state,
				product: null,
				specs: null,
				accessories: null,
				discount: null,
				discountInfo: null,
				calculatedDiscount: {},
				applicationInfo: {},
				schedeTaratura: null

			}
		case actions.SET_CONFIG_CURRENT_SECTION:
			sessionStorage.setItem("currentSection", action.value);
			return {
				...state,
				currentSection: action.value
			}
		case actions.SET_ARCHIVE_CONFIG_DATA:

			sessionStorage.setItem("specsList", JSON.stringify([...action.value.position_list]));
			sessionStorage.setItem("specsSelected", JSON.stringify([...action.value.datasheet_storage_list]));
			sessionStorage.setItem("accessoriesList", JSON.stringify([...action.value.accessory_list]));
			sessionStorage.setItem("accessoriesSelectedList", JSON.stringify([...action.value.accessory_list]));
			sessionStorage.setItem("productSelected", JSON.stringify({
				...state.product,
				...action.value.configuration
			},));
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				...action.value.discount
			}));
			return {
				...state,
				id: action.value.configuration.id,
				name: action.value.configuration.configuration,
				product: {
					...state.product,
					...action.value.configuration
				},
				specsList: [...action.value.position_list],
				specsSelected: [...action.value.datasheet_storage_list],
				accessoriesList: [...action.value.accessory_list],
				accessoriesSelected: [...action.value.accessory_list],
				discount: {
					...state.discount,
					...action.value.discount
				}
			}
		//DISCOUNT
		case actions.SET_SPECIAL_APPLICATION:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				[action.name]: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					[action.name]: action.value
				}
			}
		case actions.SET_ADDITIONAL_INFO:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				[action.name]: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					[action.name]: action.value
				}
			}
		case actions.SET_CUSTOMERS_LIST:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				customersList: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					customersList: action.value
				}
			}
		case actions.SET_SALES_ENGINEER_LIST:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				salesEngineersList: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					salesEngineersList: action.value
				}
			}
		case actions.SET_BRANCH_MANAGER_LIST:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				branchManagersList: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					branchManagersList: action.value
				}
			}
		case actions.SET_CUSTOMER:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				id_customer: action.id,
				customer: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					id_customer: action.id,
					customer: action.value
				}
			}
		case actions.SET_CUSTOMER_DISCOUNT:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				...action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					...action.value
				}
			}
		case actions.SET_SALES_ENGINEER:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				id_sales_engineer: action.id,
				sales_engineer: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					id_sales_engineer: action.id,
					sales_engineer: action.value
				}
			}
		case actions.SET_BRANCH_MANAGER:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				id_branch_manager: action.id,
				branch_manager: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					id_branch_manager: action.id,
					branch_manager: action.value
				}
			}
		case actions.UPDATE_SALES_NET_PRICE:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				sales_net_price: action.value,
				sales_manual_discount: action.perc
			}));
			return {
				...state,
				discount: {
					...state.discount,
					sales_net_price: action.value,
					sales_manual_discount: action.perc
				}
			}
		case actions.UPDATE_STANDARD_DISCOUNT:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				...action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					...action.value
				}
			}
		case actions.UPDATE_EXTRA_DISCOUNTED_PRICE:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				extra_discount: action.perc,
				extra_discounted_price: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					extra_discount: action.perc,
					extra_discounted_price: action.value
				}
			}
		case actions.UPDATE_EXTRA_DISCOUNT:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				...action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					...action.value
				}
			}
		case actions.RESET_DISCOUNT:

			return {
				...state,
				discount: initialState.discount
			}
		case actions.UPDATE_ADMIN_VIEW:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				...action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					...action.value
				}
			}
		case actions.SET_PROJECTS_LIST:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				projectsList: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					projectsList: action.value
				}
			}
		case actions.SET_PROJECT:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				id_project: action.id,
				project: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					id_project: action.id,
					project: action.value
				}
			}
		case actions.SET_CONFIGURATION_ID:
			return {
				...state,
				id: action.value
			}
		case actions.SET_CONFIGURATION_NAME:
			return {
				...state,
				name: action.value
			}
		//---

		case actions.SET_PRODUCT_DATA_EDIT:
			return {
				...state,
				product: {
					...state.product,
					family: action.family,
					product_name: action.product_name,
					rdre: action.rdre,
					size: action.size,
					slash_after_position: action.slash,
					dash_after_position: action.dash,
				}
			}

		case actions.SET_MANUAL_DISCOUNT_CLASS_LIST:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				manualDiscountClassList: action.value
			}));

			return {
				...state,
				discount: {
					...state.discount,
					manualDiscountClassList: action.value
				}
			}
		case actions.SET_MANUAL_DISCOUNT_CLASS:
			sessionStorage.setItem("customCustomer", JSON.stringify(
				action.value
			));
			return {
				...state,
				customCustomer: action.value
			}
		case actions.SET_CUSTOMER_TYPE_LIST:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				customerTypeList: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					customerTypeList: action.value
				}
			}
		case actions.SET_CUSTOMER_TYPE:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				id_customer_type: action.id,
				customer_type: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					id_customer_type: action.id,
					customer_type: action.value
				}
			}
		case actions.SET_CUSTOMER_DIRECT_FLAG:
			sessionStorage.setItem("discount", JSON.stringify({
				...state.discount,
				is_direct: action.value
			}));
			return {
				...state,
				discount: {
					...state.discount,
					is_direct: action.value
				}
			}

		default:
			break;
	}
	return state;
};

export default reducer;