import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import Archive from '../../../pages/Archive';
import HomePage from '../../Home/HomePage';
import VerifyLogin from '../../Home/VerifyLogin/VerifyLogin';
import Login from '../../Login/Login.jsx';
import NotAuthorized from '../NotAuthorized/NotAuthorized.jsx';
import ErrorPage from '../ErrorPage/ErrorPage.jsx';
import Tutorial from '../../Tutorial/index';
import Detail from '../../../pages/Configuration/Detail';
import ConfigurationsList from '../../../pages/Configuration';
import SchedeTaratura from '../../../pages/Configuration/Detail/pages/SchedeTaratura';



const Pages = () => {

  const history = useHistory();


  const is_authenticated = useSelector(state => {
    return state.application.is_authenticated;
  });


  useEffect(() => {
    if (
      is_authenticated &&
      !history.location.pathname.includes("/configuration") &&
      history.location.pathname !== "/archive" &&
      history.location.pathname !== "/schede" &&
      history.location.pathname !== "/tutorial"
    ) {
      history.replace({ search: null });
      history.push("/home");
    }

  }, [is_authenticated]);





  return (
    <>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/verifylogin" component={VerifyLogin} />
        <Route exact path="/not-authorized" component={NotAuthorized} />
        <Route exact path="/error" component={ErrorPage} />
        {/* <Route exact path="/schede" component={SchedeTaratura} /> */}
        <Route exact path="/tutorial" component={Tutorial} />

        {is_authenticated ? (
          <Route exact path="/home" component={HomePage} />
        ) : (
          <Route exact path="/home" component={Login} />
        )}
        {is_authenticated ? (
          <Route exact path="/configuration" component={ConfigurationsList} />
        ) : (
          <Route exact path="/configuration" component={Login} />
        )}
        {is_authenticated ? (
          <Route
            exact
            path="/configuration/:rdre/:size/:currentView?/:id_configuration?/:fromArchive?"
            component={Detail}
          />
        ) : (
          <Route exact path="/configuration" component={Login} />
        )}
        {/* {is_authenticated
                    ? <Route exact path='/configuration/configuration/:rdre/:size/:currentView' component={Detail} />
                    : <Route exact path='/configuration' component={Login} />
                } */}
        {is_authenticated ? (
          <Route exact path="/archive" component={Archive} />
        ) : (
          <Route exact path="/archive" component={Login} />
        )}

        {/* <Redirect to={is_authenticated ? '/home' : '/'} /> */}
      </Switch>
    </>
  );
};

export default Pages;
