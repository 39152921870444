import React, { useEffect } from "react";
import { useState } from "react";
import { useFormik } from "formik";
import MyAutocomplete from "../../../../../../../components/MyAutocomplete";
import { Grid } from "@mui/material";
import { Box, Button } from "@material-ui/core";
import {
  useGeneratedModelCode,
  useMqXS,
} from "../../../../../../../utils/Utils";
import { useHistory, useParams } from "react-router";
import { useAxios } from "../../../../../../../utils/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_SNACKBAR } from "../../../../../../../store/actions/appActions";

import "./style.css";
import { SET_SCHEDE_TARATURA } from "../../../../../../../store/actions/actions";

const A4VG = ({ data, readOnly }) => {
  const mq_xs = useMqXS();
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const updateSnackbar = (objData) =>
    dispatch({ type: UPDATE_SNACKBAR, value: objData });
  const [initialValues, setInitialValues] = useState({});
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {},
  });
  const axios = useAxios();
  const [customerNamePickList, setCustomerNamePickList] = useState([]);
  const [pickList, setPickList] = useState({});
  const rdre = params.rdre;

  const modelCode = useGeneratedModelCode();

  const handleClick = () => {
    const copyObject = { ...formik.values };
    dispatch({ type: SET_SCHEDE_TARATURA, value: copyObject });
    if (params?.id_configuration) {
      history.push(
        "/configuration/" +
          params.rdre +
          "/" +
          params.size +
          "/summary/" +
          params?.id_configuration
      );
    } else {
      history.push(
        "/configuration/" + params.rdre + "/" + params.size + "/summary"
      );
    }
  };

  /**
   *
   * @param {ChangeEvent} e event where the input value changes
   * @param {string} fieldName name of the field changed
   * change the value of the input and the computed field based on the field passed as parameter
   */
  const handleChangeComputedValue = (e, fieldName) => {
    if (fieldName === "boost_pressure_at_load_speed") {
      const newBoostPressure = parseFloat(e.target.value) || 0;

      // Calcola i nuovi valori manualmente
      const newReliefValveBSetting =
        newBoostPressure +
        (formik?.values?.testing_relevant_data?.differential_pressure_valve_b ||
          0);

      const newReliefValveASetting =
        newBoostPressure +
        (formik?.values?.testing_relevant_data?.differential_pressure_valve_a ||
          0);

      // Imposta i nuovi valori con setFieldValue
      formik.setFieldValue(
        "testing_relevant_data.boost_pressure_at_load_speed",
        e.target.value
      );
      formik.setFieldValue(
        "testing_relevant_data.reliefvalvea_setting_abs",
        newReliefValveASetting
      );
      formik.setFieldValue(
        "testing_relevant_data.reliefvalve_b_setting_abs",
        newReliefValveBSetting
      );

      // Usa il nuovo valore calcolato per la condizione
      if (
        newReliefValveASetting > 500 &&
        formik?.values?.customer_data?.special_release === "Not specified"
      ) {
        formik.setFieldValue("customer_data.special_release", "");
      } else if (newReliefValveASetting < 500) {
        formik.setFieldValue("customer_data.special_release", "Not specified");
      }
    } else if (fieldName === "differential_pressure_valve_a") {
      const newDifferentialA = parseFloat(e.target.value) || 0;

      const newReliefValveASetting =
        newDifferentialA +
        (formik?.values?.testing_relevant_data?.boost_pressure_at_load_speed ||
          0);

      formik.setFieldValue(
        "testing_relevant_data.differential_pressure_valve_a",
        e.target.value
      );
      formik.setFieldValue(
        "testing_relevant_data.reliefvalvea_setting_abs",
        newReliefValveASetting
      );

      if (
        newReliefValveASetting > 500 &&
        formik?.values?.customer_data?.special_release === "Not specified"
      ) {
        formik.setFieldValue("customer_data.special_release", "");
      } else if (newReliefValveASetting < 500) {
        formik.setFieldValue("customer_data.special_release", "Not specified");
      }
    } else {
      const newDifferentialB = parseFloat(e.target.value) || 0;

      const newReliefValveBSetting =
        newDifferentialB +
        (formik?.values?.testing_relevant_data?.boost_pressure_at_load_speed ||
          0);

      formik.setFieldValue(
        "testing_relevant_data.differential_pressure_valve_b",
        e.target.value
      );
      formik.setFieldValue(
        "testing_relevant_data.reliefvalve_b_setting_abs",
        newReliefValveBSetting
      );
    }
  };

  const getPickList = () => {
    axios
      .get("calibration/GetPicklistValue", { params: { rdre: rdre } })
      .then((res) => {
        setPickList(res.data);
      })
      .catch((error) =>
        updateSnackbar({
          open: true,
          message: "Unable to get picklists",
          severity: "error",
        })
      );
  };

  // const getCalibration = () => {
  //   axios
  //     .get("calibration/GetCalibration", { params: { rdre: rdre }, })
  //     .then((res) => {
  //       if (data?.schedeTaratura) {
  //         setInitialValues(data?.schedeTaratura)
  //       } else {
  //         setInitialValues(res.data);
  //       }

  //     })
  //     .catch((error) =>
  //       updateSnackbar({
  //         open: true,
  //         message: "Unable to get calibrations",
  //         severity: "error",
  //       })
  //     );
  // };

  useEffect(() => {
    // getCalibration();
    getPickList();
    if (data?.discountInfo) {
      const item = data.discountInfo.find((d) => d.type === "customer");

      setCustomerNamePickList([
        {
          id: 1,
          value: "No",
        },
        {
          id: 2,
          value: item.optionValue,
        },
      ]);
    }

    if (data?.schedeTaratura) {
      Object.keys(data?.schedeTaratura?.testing_relevant_data).forEach(
        (key) => {
          if (
            data.schedeTaratura.testing_relevant_data[key] &&
            (key !== "nominal_voltage" ||
              key !== "pressurecutoff_fstep1_adj_to")
          ) {
            data.schedeTaratura.testing_relevant_data[key] = parseFloat(
              data.schedeTaratura.testing_relevant_data[key]
            );
          }
        }
      );
    }

    // preparing the initial values from the configuration passed as a parameter of the function
    const values = {
      info: {
        version_text: data?.schedeTaratura?.info?.version_text || "",
      },
      general_info_features: {
        material_description: modelCode || "",
      },
      customer_data: {
        customer: data?.schedeTaratura?.customer_data?.customer || {
          id: 0,
          value: "No",
        }, //se si quello di default della conf, se no vuoto
        customer_pn: data?.schedeTaratura?.customer_data?.customer_pn || "", // obbligatorio se inserisci il customer
        special_release:
          data?.schedeTaratura?.testing_relevant_data
            ?.reliefvalvea_setting_abs &&
          data?.schedeTaratura?.testing_relevant_data
            ?.reliefvalvea_setting_abs > 500
            ? data?.schedeTaratura?.customer_data?.special_release || ""
            : "Not specified", //dipende dal campo b38, //dipende dal campo b38
        name_plate_standard:
          values?.customer_data?.customer?.value !== "No"
            ? "standard with customer number"
            : "standard without customer number", //se c'è il customer allora "standard with customer number", se no "standard without customer number
        application_dc_branch_code: data?.schedeTaratura?.customer_data
          ?.application_dc_branch_code || {
          id: 3,
          value: "OTHERS",
        },
        approval_company_external:
          data?.accessories?.find(
            (a) => a.value.accessory === "Test Certificates"
          )?.value?.value !== "Inspection certific.3.1 apiece"
            ? { id: 248, value: "Standard(without homologation)" }
            : null,
        test_certificate:
          data?.schedeTaratura?.customer_data?.test_certificate ||
          data?.accessories?.find(
            (a) => a.value.accessory === "Test Certificates"
          )?.value?.value === "No"
            ? { id: 265, value: "without" }
            : data?.schedeTaratura?.customer_data?.approval_company_external ||
              null,
        language_code_f_test_certif:
          data?.accessories?.find(
            (a) => a.value.accessory === "Test Certificates"
          )?.value?.value === "No"
            ? { id: 276, value: "without" }
            : data?.schedeTaratura?.customer_data
                ?.language_code_f_test_certif || null,
        paintprimer:
          data?.accessories?.find((a) => a.family === "S6")?.value?.value ===
            "No" &&
          data?.accessories?.find((a) => a.family === "S7")?.value?.value ===
            "No"
            ? { id: 160, value: "without" }
            : data?.schedeTaratura?.customer_data?.paintprimer || null,
        painttop_coat:
          data?.accessories?.find((a) => a.family === "S7")?.value?.value ===
          "No"
            ? { id: 203, value: "without" }
            : data?.schedeTaratura?.customer_data?.painttop_coat || null,
        paint_specification:
          data?.accessories?.find((a) => a.family === "S6")?.value?.value ===
            "No" &&
          data?.accessories?.find((a) => a.family === "S7")?.value?.value ===
            "No"
            ? { id: 240, value: "without" }
            : data?.schedeTaratura?.customer_data?.paint_specification || null,
        specification_code:
          data?.schedeTaratura?.customer_data?.specification_code || "",
        conservation: data?.schedeTaratura?.customer_data?.conservation || {
          id: 111,
          value: "without",
        },
      },
      assembly_rel_data: {
        moa_hw_lever:
          data?.specs?.find((a) => a.position_desc === "Control device")?.value
            ?.value !== "HW"
            ? { id: 136, value: "Not Specified" }
            : data?.schedeTaratura?.assembly_rel_data?.moa_hw_lever || null,
        moa_solenoid_a:
          !data?.specs
            ?.find((a) => a.position_desc === "Control device")
            ?.value?.value.includes("E") &&
          !data?.specs
            ?.find((a) => a.position_desc === "Control device")
            ?.value?.value.includes("B")
            ? { id: 150, value: "OHNE ANGABE" }
            : data?.schedeTaratura?.assembly_rel_data?.moa_solenoid_a || null,
        moa_solenoid_b:
          !data?.specs
            ?.find((a) => a.position_desc === "Control device")
            ?.value?.value.includes("E") &&
          !data?.specs
            ?.find((a) => a.position_desc === "Control device")
            ?.value?.value.includes("B")
            ? { id: 154, value: "Not Specified" }
            : data?.schedeTaratura?.assembly_rel_data?.moa_solenoid_b || null,
        moa_port_plate: data?.schedeTaratura?.assembly_rel_data
          ?.moa_port_plate || { id: 121, value: "M1 not rotated" },
        moa_through_drive: data?.schedeTaratura?.assembly_rel_data
          ?.moa_through_drive || { id: 123, value: "M000 = WAAGRECHT" },
        moa_pumpsupporttheads_position: data?.schedeTaratura?.assembly_rel_data
          ?.moa_pumpsupporttheads_position || {
          id: 116,
          value: "OHNE ANGABE",
        },
        moa_flangecoverpresstightcov: data?.schedeTaratura?.assembly_rel_data
          ?.moa_flangecoverpresstightcov || { id: 126, value: "2-hole 074A" },
        moa_flushing_valve_port_t: data?.schedeTaratura?.assembly_rel_data
          ?.moa_flushing_valve_port_t || { id: 141, value: "Not Specified" },
        moa_da_lever: data?.schedeTaratura?.assembly_rel_data?.moa_da_lever || {
          id: 144,
          value: "Not specified",
        },
        moa_sv_in_port_block: data?.schedeTaratura?.assembly_rel_data
          ?.moa_sv_in_port_block || { id: 147, value: "Not specified" },
      },
      testing_relevant_data: {
        at_high_pressure_bc_re:
          data?.specs?.find((a) => a.position_desc === "DA control value")
            ?.value?.value !== "1"
            ? data?.schedeTaratura?.testing_relevant_data
                ?.at_high_pressure_bc_re || null
            : "not specified",
        at_high_pressure_bc_rs:
          data?.specs?.find((a) => a.position_desc === "DA control value")
            ?.value?.value !== "1"
            ? data?.schedeTaratura?.testing_relevant_data
                ?.at_high_pressure_bc_rs || null
            : "not specified",
        at_working_pressure:
          data?.schedeTaratura?.testing_relevant_data?.at_working_pressure ||
          data?.specs?.find((a) => a.position_desc === "DA control value")
            ?.value?.value !== "1"
            ? data?.schedeTaratura?.testing_relevant_data
                ?.at_working_pressure || null
            : "not specified",
        blockcurve_regend_at:
          data?.schedeTaratura?.testing_relevant_data?.blockcurve_regend_at ||
          data?.specs?.find((a) => a.position_desc === "DA control value")
            ?.value?.value !== "1"
            ? data?.schedeTaratura?.testing_relevant_data
                ?.blockcurve_regend_at || null
            : "not specified",
        blockcurve_regstart_at:
          data?.schedeTaratura?.testing_relevant_data?.blockcurve_regstart_at ||
          data?.specs?.find((a) => a.position_desc === "DA control value")
            ?.value?.value !== "1"
            ? data?.schedeTaratura?.testing_relevant_data
                ?.blockcurve_regstart_at || null
            : "not specified",
        boost_pressure_at_load_speed:
          data?.schedeTaratura?.testing_relevant_data
            ?.boost_pressure_at_load_speed || null,
        dg_characteristic_regend:
          data?.schedeTaratura?.testing_relevant_data
            ?.dg_characteristic_regend || null,
        differential_pressure_valve_a:
          data?.schedeTaratura?.testing_relevant_data
            ?.differential_pressure_valve_a || null,
        differential_pressure_valve_b:
          data?.schedeTaratura?.testing_relevant_data
            ?.differential_pressure_valve_b || null,
        dre_char_regend_contpower:
          data?.schedeTaratura?.testing_relevant_data
            ?.dre_char_regend_contpower || null,
        dre_char_regend_contpres:
          data?.schedeTaratura?.testing_relevant_data
            ?.dre_char_regend_contpres || null,
        dre_char_regstart_contpower:
          data?.schedeTaratura?.testing_relevant_data
            ?.dre_char_regstart_contpower || null,
        dre_char_regstart_contpres:
          data?.schedeTaratura?.testing_relevant_data
            ?.dre_char_regstart_contpres || null,
        input_power:
          data?.schedeTaratura?.testing_relevant_data?.input_power || null,
        max_displacement_a_ccm:
          data?.schedeTaratura?.testing_relevant_data?.max_displacement_a_ccm ||
          null,
        max_displacement_b_ccm:
          data?.schedeTaratura?.testing_relevant_data?.max_displacement_b_ccm ||
          null,
        max_speed_load:
          data?.schedeTaratura?.testing_relevant_data?.max_speed_load || null,
        max_speed_wo_load:
          data?.schedeTaratura?.testing_relevant_data?.max_speed_wo_load ||
          null,
        nominal_voltage:
          data?.schedeTaratura?.testing_relevant_data?.nominal_voltage || null,
        operating_speed:
          data?.schedeTaratura?.testing_relevant_data?.operating_speed || null,
        pressurecutoff_fstep1_adj_to:
          data?.schedeTaratura?.testing_relevant_data
            ?.pressurecutoff_fstep1_adj_to ||
          data?.specs?.find((a) => a.position_desc === "Pressure cut-off")
            ?.value?.value === "D"
            ? data?.schedeTaratura?.testing_relevant_data
                ?.pressurecutoff_fstep1_adj_to || ""
            : "Without",
        q_char_regend:
          data?.schedeTaratura?.testing_relevant_data?.q_char_regend ||
          data?.specs?.find((a) => a.position_desc === "DA control value")
            ?.value?.value !== "1"
            ? data?.schedeTaratura?.testing_relevant_data?.q_char_regend || null
            : "not specified",
        reliefvalve_b_setting_abs:
          data?.schedeTaratura?.testing_relevant_data
            ?.reliefvalve_b_setting_abs || 0,
        reliefvalvea_setting_abs:
          data?.schedeTaratura?.testing_relevant_data
            ?.reliefvalvea_setting_abs || 0,
      },
      special_features: {
        sp_td_cover_plate:
          data?.schedeTaratura?.special_features?.sp_td_cover_plate || null,
        note: data?.schedeTaratura?.special_features?.note || "",
      },
    };

    setInitialValues(values);
  }, [data]);

  console.log("InitialValues", modelCode);

  return (
    <form
      id="schede_taratura_form"
      style={{
        padding: "24px",
        borderRadius: "20px",
        backgroundColor: "white",
      }}
      onSubmit={formik.handleSubmit}
    >
      <h1> Scheda di taratura A4VG - 32</h1>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>R8M_1_PV5630V</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              disabled={readOnly}
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              name={"info.version_text"}
              value={formik?.values?.info?.version_text || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <h2>General Info Features</h2>
        </Grid>

        <Grid item xs={4}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Material Description
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              disabled={true}
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              name={"general_info_features.material_description"}
              value={
                formik?.values?.general_info_features?.material_description ||
                ""
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <h2>Customer Data</h2>
        </Grid>
        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>Customer name</p>

          <MyAutocomplete
            isMultiple={false}
            defaultValue={formik?.values?.customer_data?.customer || null} // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("customer_data.customer", c);
            }}
            disabled={readOnly}
            data={customerNamePickList} // Lista di opzioni
            name="customer_data.customer"
            label={""}
          />
        </Grid>

        {formik?.values?.customer_data?.customer &&
          formik?.values?.customer_data?.customer?.value !== "No" && (
            <Grid item xs={3}>
              <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
                customer p/n
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px 20px",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  borderRadius: "15px",
                  position: "relative",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                }}
              >
                <input
                  style={{
                    outline: "none",
                    border: "none",
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  disabled={readOnly}
                  name={"customer_data.customer_pn"}
                  value={formik?.values?.customer_data?.customer_pn || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </Grid>
          )}
        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Special Release No.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={
                readOnly ||
                formik?.values?.testing_relevant_data
                  ?.reliefvalvea_setting_abs < 500
              }
              name={"customer_data.special_release"}
              value={formik?.values?.customer_data?.special_release || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Name plate standard
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              disabled={true}
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              name={"customer_data.name_plate_standard"}
              value={formik?.values?.customer_data?.name_plate_standard || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            application - DC-Branch-Code
          </p>

          <MyAutocomplete
            isMultiple={false}
            defaultValue={
              formik?.values?.customer_data?.application_dc_branch_code || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue(
                "customer_data.application_dc_branch_code",
                c
              );
            }}
            disabled={readOnly}
            data={pickList?.application_dc_branch_code || []} // Lista di opzioni
            name="customer_data.application_dc_branch_code"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Approval Company (External)
          </p>
          {/* // se test certificate è No , defaultValue : 'without' */}
          <MyAutocomplete
            isMultiple={false}
            disabled={
              readOnly
                ? readOnly
                : data?.accessories?.find(
                    (a) => a.value.accessory === "Test Certificates"
                  )?.value?.value !== "Inspection certific.3.1 apiece"
            }
            defaultValue={
              formik?.values?.customer_data?.approval_company_external || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue(
                "customer_data.approval_company_external",
                c
              );
            }}
            data={pickList?.approval_company_external || []} // Lista di opzioni
            name="customer_data.approval_company_external"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Test Certificate
          </p>

          <MyAutocomplete
            isMultiple={false}
            disabled={
              readOnly
                ? readOnly
                : data?.accessories?.find(
                    (a) => a.value.accessory === "Test Certificates"
                  )?.value?.value === "No"
            }
            defaultValue={
              formik?.values?.customer_data?.test_certificate || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("customer_data.test_certificate", c);
            }}
            data={pickList?.test_certificate || []} // Lista di opzioni
            name="customer_data.test_certificate"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>Language code</p>

          <MyAutocomplete
            isMultiple={false}
            disabled={
              readOnly
                ? readOnly
                : data?.accessories?.find(
                    (a) => a.value.accessory === "Test Certificates"
                  )?.value?.value === "No"
            }
            defaultValue={
              formik?.values?.customer_data?.language_code_f_test_certif || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue(
                "customer_data.language_code_f_test_certif",
                c
              );
            }}
            data={pickList?.language_code_f_test_certif || []} // Lista di opzioni
            name="customer_data.language_code_f_test_certif"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>Paint/Primer</p>

          <MyAutocomplete
            isMultiple={false}
            disabled={
              readOnly
                ? readOnly
                : data?.accessories?.find((a) => a.family === "S6")?.value
                    ?.value === "No" &&
                  data?.accessories?.find((a) => a.family === "S7")?.value
                    ?.value === "No"
            }
            defaultValue={formik?.values?.customer_data?.paintprimer || null} // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("customer_data.paintprimer", c);
            }}
            data={pickList?.paintprimer || []} // Lista di opzioni
            name="customer_data.paintprimer"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Paint/top coat
          </p>

          <MyAutocomplete
            isMultiple={false}
            disabled={
              readOnly
                ? readOnly
                : data?.accessories?.find((a) => a.family === "S7")?.value
                    ?.value === "No"
            }
            defaultValue={formik?.values?.customer_data?.painttop_coat || null} // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("customer_data.painttop_coat", c);
            }}
            data={pickList?.painttop_coat || []} // Lista di opzioni
            name="customer_data.painttop_coat"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Paint Specification
          </p>

          <MyAutocomplete
            isMultiple={false}
            disabled={
              readOnly
                ? readOnly
                : data?.accessories?.find((a) => a.family === "S6")?.value
                    ?.value === "No" &&
                  data?.accessories?.find((a) => a.family === "S7")?.value
                    ?.value === "No"
            }
            defaultValue={
              formik?.values?.customer_data?.paint_specification || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("customer_data.paint_specification", c);
            }}
            data={pickList?.paint_specification || []} // Lista di opzioni
            name="customer_data.paint_specification"
            label={""}
          />
        </Grid>

        {true && (
          <>
            <Grid item xs={3}>
              <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
                specification code
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px 20px",
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                  borderRadius: "15px",
                  position: "relative",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
                }}
              >
                <input
                  style={{
                    outline: "none",
                    border: "none",
                    backgroundColor: "transparent",
                    width: "100%",
                  }}
                  disabled={readOnly}
                  name={"customer_data.specification_code"}
                  value={
                    formik?.values?.customer_data?.specification_code || ""
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </Grid>

            <Grid item xs={3}>
              <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
                Conservation
              </p>

              <MyAutocomplete
                isMultiple={false}
                defaultValue={
                  formik?.values?.customer_data?.conservation || null
                } // Valore di default del campo customer
                onChange={(e, c) => {
                  formik.setFieldValue("customer_data.conservation", c);
                }}
                disabled={readOnly}
                data={pickList?.conservation || []} // Lista di opzioni
                name="customer_data.conservation"
                label={""}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <h2>ASSEMBLY REL. DATA</h2>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>MOA HW-lever</p>

          <MyAutocomplete
            isMultiple={false}
            disabled={
              readOnly
                ? readOnly
                : data?.specs?.find((a) => a.position_desc === "Control device")
                    ?.value?.value !== "HW"
            }
            defaultValue={
              formik?.values?.assembly_rel_data?.moa_hw_lever || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("assembly_rel_data.moa_hw_lever", c);
            }}
            data={pickList?.moa_hw_lever || []} // Lista di opzioni
            name="assembly_rel_data.moa_hw_lever"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            MOA Solenoid A
          </p>

          <MyAutocomplete
            isMultiple={false}
            disabled={
              readOnly
                ? readOnly
                : !data?.specs
                    ?.find((a) => a.position_desc === "Control device")
                    ?.value?.value.includes("E") &&
                  !data?.specs
                    ?.find((a) => a.position_desc === "Control device")
                    ?.value?.value.includes("B")
            }
            defaultValue={
              formik?.values?.assembly_rel_data?.moa_solenoid_a || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("assembly_rel_data.moa_solenoid_a", c);
            }}
            data={pickList?.moa_solenoid_a || []} // Lista di opzioni
            name="assembly_rel_data.moa_solenoid_a"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            MOA Solenoid B
          </p>

          <MyAutocomplete
            isMultiple={false}
            disabled={
              readOnly
                ? readOnly
                : !data?.specs
                    ?.find((a) => a.position_desc === "Control device")
                    ?.value?.value.includes("E") &&
                  !data?.specs
                    ?.find((a) => a.position_desc === "Control device")
                    ?.value?.value.includes("B")
            }
            defaultValue={
              formik?.values?.assembly_rel_data?.moa_solenoid_b || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("assembly_rel_data.moa_solenoid_b", c);
            }}
            data={pickList?.moa_solenoid_b || []} // Lista di opzioni
            name="assembly_rel_data.moa_solenoid_b"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            MOA Port-Plate
          </p>

          <MyAutocomplete
            isMultiple={false}
            defaultValue={
              formik?.values?.assembly_rel_data?.moa_port_plate || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("assembly_rel_data.moa_port_plate", c);
            }}
            disabled={readOnly}
            data={pickList?.moa_port_plate || []} // Lista di opzioni
            name="assembly_rel_data.moa_port_plate"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            MOA Through Drive
          </p>

          <MyAutocomplete
            isMultiple={false}
            defaultValue={
              formik?.values?.assembly_rel_data?.moa_through_drive || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("assembly_rel_data.moa_through_drive", c);
            }}
            disabled={readOnly}
            data={pickList?.moa_through_drive || []} // Lista di opzioni
            name="assembly_rel_data.moa_through_drive"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            MOA PumpSupportTheads Position
          </p>

          <MyAutocomplete
            isMultiple={false}
            defaultValue={
              formik?.values?.assembly_rel_data
                ?.moa_pumpsupporttheads_position || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue(
                "assembly_rel_data.moa_pumpsupporttheads_position",
                c
              );
            }}
            disabled={readOnly}
            data={pickList?.moa_pumpsupporttheads_position || []} // Lista di opzioni
            name="assembly_rel_data.moa_pumpsupporttheads_position"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            MOA FlangeCover/Press.TightCov
          </p>

          <MyAutocomplete
            isMultiple={false}
            defaultValue={
              formik?.values?.assembly_rel_data?.moa_flangecoverpresstightcov ||
              null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue(
                "assembly_rel_data.moa_flangecoverpresstightcov",
                c
              );
            }}
            disabled={readOnly}
            data={pickList?.moa_flangecoverpresstightcov || []} // Lista di opzioni
            name="assembly_rel_data.moa_flangecoverpresstightcov"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            MOA Flushing Valve Port T
          </p>

          <MyAutocomplete
            isMultiple={false}
            disabled={readOnly}
            defaultValue={
              formik?.values?.assembly_rel_data?.moa_flushing_valve_port_t ||
              null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue(
                "assembly_rel_data.moa_flushing_valve_port_t",
                c
              );
            }}
            data={pickList?.moa_flushing_valve_port_t || []} // Lista di opzioni
            name="assembly_rel_data.moa_flushing_valve_port_t"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>MOA DA-lever</p>

          <MyAutocomplete
            // Valore di default del campo customer
            isMultiple={false}
            disabled={readOnly}
            defaultValue={
              formik?.values?.assembly_rel_data?.moa_da_lever || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("assembly_rel_data.moa_da_lever", c);
            }}
            data={pickList?.moa_da_lever || []} // Lista di opzioni
            name="assembly_rel_data.moa_da_lever"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            MOA SV in port block
          </p>

          <MyAutocomplete
            isMultiple={false}
            disabled={readOnly}
            defaultValue={
              formik?.values?.assembly_rel_data?.moa_sv_in_port_block || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("assembly_rel_data.moa_sv_in_port_block", c);
            }}
            data={pickList?.moa_sv_in_port_block || []} // Lista di opzioni
            name="assembly_rel_data.moa_sv_in_port_block"
            label={""}
          />
        </Grid>

        <Grid item xs={12}>
          <h2>TESTING RELEVANT DATA</h2>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Max. Speed (Load)
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.max_speed_load"}
              value={formik?.values?.testing_relevant_data?.max_speed_load}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 500-4000 by increments of 50
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Max. Speed (w.o. Load)
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.max_speed_wo_load"}
              value={formik?.values?.testing_relevant_data?.max_speed_wo_load}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 500-4000 by increments of 50
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Operating Speed
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.operating_speed"}
              value={formik?.values?.testing_relevant_data?.operating_speed}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 500-4000 by increments of 50
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>Input Power</p>
          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.input_power"}
              value={formik?.values?.testing_relevant_data?.input_power}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 500-4000 by increments of 50
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Boost Pressure at Load Speed
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.boost_pressure_at_load_speed"}
              value={
                formik?.values?.testing_relevant_data
                  ?.boost_pressure_at_load_speed
              }
              onChange={(e) =>
                handleChangeComputedValue(e, "boost_pressure_at_load_speed")
              }
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 18-40 by increments of 1
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Max. Displacement A (ccm)
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.max_displacement_a_ccm"}
              value={
                formik?.values?.testing_relevant_data?.max_displacement_a_ccm
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Max. Displacement B (ccm)
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.max_displacement_b_ccm"}
              value={
                formik?.values?.testing_relevant_data?.max_displacement_b_ccm
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            ReliefValve.A Setting abs.
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={true}
              type="number"
              name={"testing_relevant_data.reliefvalvea_setting_abs"}
              value={
                formik?.values?.testing_relevant_data?.reliefvalvea_setting_abs
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Differential Pressure Valve A
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.differential_pressure_valve_a"}
              value={
                formik?.values?.testing_relevant_data
                  ?.differential_pressure_valve_a
              }
              onChange={(e) =>
                handleChangeComputedValue(e, "differential_pressure_valve_a")
              }
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 300-530 by increments of 10
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            ReliefValve.B Setting abs.
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={true}
              type="number"
              name={"testing_relevant_data.reliefvalve_b_setting_abs"}
              value={
                formik?.values?.testing_relevant_data?.reliefvalve_b_setting_abs
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Differential Pressure Valve B
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.differential_pressure_valve_b"}
              value={
                formik?.values?.testing_relevant_data
                  ?.differential_pressure_valve_b
              }
              onChange={(e) =>
                handleChangeComputedValue(e, "differential_pressure_valve_b")
              }
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 300-530 by increments of 10
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            PressureCutOff F.Step1 adj. to
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              name={"testing_relevant_data.pressurecutoff_fstep1_adj_to"}
              value={
                formik?.values?.testing_relevant_data
                  ?.pressurecutoff_fstep1_adj_to || ""
              }
              disabled={
                readOnly
                  ? readOnly
                  : data?.specs?.find(
                      (a) => a.position_desc === "Pressure cut-off"
                    )?.value?.value !== "D"
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Nominal Volatage (Control)
          </p>

          <MyAutocomplete
            isMultiple={false}
            defaultValue={
              formik?.values?.testing_relevant_data?.nominal_voltage || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("testing_relevant_data.nominal_voltage", c);
            }}
            disabled={readOnly}
            data={pickList?.nominal_voltage || []} // Lista di opzioni
            name="testing_relevant_data.nominal_voltage"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            DRE-Char. Reg.Start Cont.Power
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.dre_char_regstart_contpower"}
              value={
                formik?.values?.testing_relevant_data
                  ?.dre_char_regstart_contpower
              }
              step="any"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 5.00-500.00
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            DRE-Char. Reg.Start Cont.Press
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.dre_char_regstart_contpres"}
              value={
                formik?.values?.testing_relevant_data
                  ?.dre_char_regstart_contpres
              }
              step="any"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 5.00-500.00
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            DRE-Char. Reg.End Cont.Power
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.dre_char_regend_contpower"}
              value={
                formik?.values?.testing_relevant_data?.dre_char_regend_contpower
              }
              step="any"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 5.00-500.00
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            DRE-Char. Reg.End Cont.Press
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.dre_char_regend_contpres"}
              value={
                formik?.values?.testing_relevant_data?.dre_char_regend_contpres
              }
              step="any"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 5.00-500.00
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            DG-Characteristic Reg.End
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              disabled={readOnly}
              type="number"
              name={"testing_relevant_data.dg_characteristic_regend"}
              value={
                formik?.values?.testing_relevant_data?.dg_characteristic_regend
              }
              step="any"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
              Number between 5.00-500.00
            </p>
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Blockcurve Reg.Start at
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              type={
                data?.specs?.find((a) => a.position_desc === "DA control value")
                  ?.value?.value !== "1"
                  ? "number"
                  : "text"
              }
              disabled={
                readOnly
                  ? readOnly
                  : data?.specs?.find(
                      (a) => a.position_desc === "DA control value"
                    )?.value?.value === "1"
              }
              name={"testing_relevant_data.blockcurve_regstart_at"}
              value={
                formik?.values?.testing_relevant_data?.blockcurve_regstart_at
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {true && (
              <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
                Number by increments of 50
              </p>
            )}
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            At High Pressure BC Rs
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              type={
                data?.specs?.find((a) => a.position_desc === "DA control value")
                  ?.value?.value !== "1"
                  ? "number"
                  : "text"
              }
              disabled={
                readOnly
                  ? readOnly
                  : data?.specs?.find(
                      (a) => a.position_desc === "DA control value"
                    )?.value?.value === "1"
              }
              name={"testing_relevant_data.at_high_pressure_bc_rs"}
              value={
                formik?.values?.testing_relevant_data?.at_high_pressure_bc_rs
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {true && (
              <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
                Number by increments of 10
              </p>
            )}
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Blockcurve Reg.End at
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              type={
                data?.specs?.find((a) => a.position_desc === "DA control value")
                  ?.value?.value !== "1"
                  ? "number"
                  : "text"
              }
              disabled={
                readOnly
                  ? readOnly
                  : data?.specs?.find(
                      (a) => a.position_desc === "DA control value"
                    )?.value?.value === "1"
              }
              name={"testing_relevant_data.blockcurve_regend_at"}
              value={
                formik?.values?.testing_relevant_data?.blockcurve_regend_at
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {true && (
              <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
                Number by increments of 50
              </p>
            )}
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            At High Pressure BC Re
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              type={
                data?.specs?.find((a) => a.position_desc === "DA control value")
                  ?.value?.value !== "1"
                  ? "number"
                  : "text"
              }
              disabled={
                readOnly
                  ? readOnly
                  : data?.specs?.find(
                      (a) => a.position_desc === "DA control value"
                    )?.value?.value === "1"
              }
              name={"testing_relevant_data.at_high_pressure_bc_re"}
              value={
                formik?.values?.testing_relevant_data?.at_high_pressure_bc_re
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {true && (
              <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
                Number by increments of 10
              </p>
            )}
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            Q-Char. Reg.End
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              alignItems: "center",
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              type={
                data?.specs?.find((a) => a.position_desc === "DA control value")
                  ?.value?.value !== "1"
                  ? "number"
                  : "text"
              }
              disabled={
                readOnly
                  ? readOnly
                  : data?.specs?.find(
                      (a) => a.position_desc === "DA control value"
                    )?.value?.value === "1"
              }
              name={"testing_relevant_data.q_char_regend"}
              value={formik?.values?.testing_relevant_data?.q_char_regend}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {true && (
              <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
                Number by increments of 50
              </p>
            )}
          </div>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            At working pressure
          </p>

          <div
            style={{
              display: "flex",
              gap: 3,
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              type={
                data?.specs?.find((a) => a.position_desc === "DA control value")
                  ?.value?.value !== "1"
                  ? "number"
                  : "text"
              }
              disabled={
                readOnly
                  ? readOnly
                  : data?.specs?.find(
                      (a) => a.position_desc === "DA control value"
                    )?.value?.value === "1"
              }
              name={"testing_relevant_data.at_working_pressure"}
              value={formik?.values?.testing_relevant_data?.at_working_pressure}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {true && (
              <p style={{ color: "orange", fontSize: 12, fontWeight: "bold" }}>
                Number by increments of 50
              </p>
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <h2>SPECIAL FEATURES</h2>
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
            SP TD Cover plate
          </p>

          <MyAutocomplete
            isMultiple={false}
            defaultValue={
              formik?.values?.special_features?.sp_td_cover_plate || null
            } // Valore di default del campo customer
            onChange={(e, c) => {
              formik.setFieldValue("special_features.sp_td_cover_plate", c);
            }}
            disabled={readOnly}
            data={pickList?.sp_td_cover_plate || []} // Lista di opzioni
            name="special_features.sp_td_cover_plate"
            label={""}
          />
        </Grid>

        <Grid item xs={3}>
          <p style={{ margin: "0 0 5px 0", fontSize: "14px" }}>Note</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "15px 20px",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: "15px",
              position: "relative",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
            }}
          >
            <input
              disabled={readOnly}
              style={{
                outline: "none",
                border: "none",
                backgroundColor: "transparent",
                width: "100%",
              }}
              name={"special_features.note"}
              value={formik?.values?.special_features?.note || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </Grid>
      </Grid>

      {!readOnly && (
        <div style={{ padding: "30px 0" }}>
          <Box mr={mq_xs ? 0 : 1}>
            <Button
              disabled={false}
              variant="contained"
              color="inherit"
              size={mq_xs ? "small" : "medium"}
              onClick={handleClick}
            >
              Summary
            </Button>
          </Box>
        </div>
      )}
    </form>
  );
};

export default A4VG;
