import React, { useState, useEffect } from "react";
import { useRoleDesc } from "../../../utils/useRoleDesc";
import { useParams } from "react-router";
import { useAxios } from "../../../utils/useAxios";
import ModelCodeBar from "../../../components/Configuration/ModelCodeBar/ModelCodeBar";
import LoadingBar from "../../../components/UI/LoadingBar/LoadingBar";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import Spec from "./components/Spec";
import Accessory from "./components/Accessory";
import {
  SET_SPECS,
  SET_ACCS,
  SET_BASE_PRICE,
} from "../../../store/actions/actions";
import PriceDrawer from "./components/PriceDrawer";
import ManualPriceDrawer from "./components/Spec/components/ManualPriceModal/ManualPriceModal";
import SpecialField from "./components/Spec/components/SpecialField";
import { useMqXS } from "../../../utils/Utils";
import DiscountPage from "./pages/Discount";
import { Button } from "@material-ui/core";
import TitleBox from "../../../components/TitleBox";
import DatasheetLegend from "../../../components/UI/DatasheetLegend/DatasheetLegend";
import { BlockOutlined, DescriptionOutlined } from "@material-ui/icons";
import SummaryHeader from "./components/SummaryHeader";
import { useCountry } from "../../../utils/useCountry";
import { createDiscountObj } from "../../../utils/createDiscountObj";
import { SET_FINAL_PRICE } from "../../../store/actions/actions";
import SchedeTaratura from "./pages/SchedeTaratura";

const Detail = () => {
  const whichRole = useRoleDesc();
  const country = useCountry();
  const params = useParams();
  const axios = useAxios();
  const mq_xs = useMqXS();
  const dispatch = useDispatch();
  const specs = useSelector((state) => {
    return state.configuration.specs;
  });
  const accs = useSelector((state) => {
    return state.configuration.accessories;
  });
  const product = useSelector((state) => {
    return state?.configuration?.product;
  });
  const project = useSelector((state) => {
    return state?.configuration?.discount?.project;
  });
  const calculatedDiscount = useSelector((state) => {
    return state.configuration.calculatedDiscount;
  });
  const finalPrice = useSelector((state) => {
    return state.configuration.finalPrice;
  });
  const user = useSelector((state) => {
    return state?.application?.user?.email;
  });
  const archiveInfo = useSelector((state) => {
    return state?.configuration?.archiveInfo;
  });
  const configuration = useSelector((state) => {
    return state?.configuration;
  });
  const [results, setResults] = useState(specs ? specs : []);
  const [accessories, setAccessories] = useState(accs ? accs : []);
  const [readOnly, setReadOnly] = useState(false);
  const [showSummaryHeader, setShowSummaryHeader] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const [valueSelected, setValueSelected] = useState();
  const [specSelected, setSpecSelected] = useState();
  const [accessorySelected, setAccessorySelected] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modelCode, setModelCode] = useState(null);
  const [basePrice, setBasePrice] = useState(configuration.base_price || 0);
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const [openPriceDrawer, setOpenPriceDrawer] = useState(false);

  const [discountList, setDiscountList] = useState();
  const url = `https://www.boschrexroth.com/en/de/search.html?q=${params.rdre.substring(
    0,
    5
  )}&num=10&origin=header&s=download`;

  const openMediaDirectory = () => {
    window.open(url, "_blank").focus();
  };

  const getSpecsQuery = async (body, whichRole) => {
    try {
      const mainResult = await axios.get("position/GetAllPosition", {
        params: body,
      });

      if (
        (whichRole === "Admin") |
        (whichRole === "Internal") |
        (whichRole === "VIP")
      ) {
        const partialResult = await axios.get(
          "position/GetAllPositionValueConfidential",
          {
            params: body,
          }
        );
        if (partialResult) {
          for (let i = 0; i < mainResult.data.length; i++) {
            for (let j = 0; j < partialResult.data.length; j++) {
              if (
                mainResult.data[i].position === partialResult.data[j].position
              ) {
                mainResult.data[i].value_list =
                  partialResult.data[j].value_list;
              }
            }
          }
        }
      }
      mainResult.data.forEach((obj) => {
        if (obj.value_list && obj.value_list.length === 1) {
          obj.value = obj.value_list[0];
        }
      });
      setResults(mainResult.data);
      dispatch({ type: SET_SPECS, value: mainResult.data });
    } catch (e) {
      console.log(e);
    }
  };

  console.log('alloptionresults', results);
  const getAccessoryQuery = async (body, whichRole) => {
    try {
      const mainResult = await axios.get("accessory/GetAllAccessory", {
        params: body,
      });

      if (
        (whichRole === "Admin") |
        (whichRole === "Internal") |
        (whichRole === "VIP")
      ) {
        const partialResult = await axios.get(
          "accessory/GetAccessoryValueConfidential",
          { params: body }
        );
        if (partialResult) {
          for (var i = 0; i < mainResult.data.length; i++) {
            var value_list = mainResult.data[i].value_list;
            // verificare se value_list è un oggetto o un array di oggetti

            for (var j = 0; j < value_list.length; j++) {
              var id = value_list[j].id;
              var filterArr = partialResult.data.filter(function (x) {
                return x.id === id;
              });
              if (filterArr.length > 0) {
                value_list[j]["price"] = filterArr[0].price;
                value_list[j]["is_gross"] = filterArr[0].is_gross;
              }
            }
          }
        }
      }

      // creo opzioni si e no per gli accesori con value_list.length === 1

      mainResult.data.forEach((accessory) => {
        if (accessory.value_list.length === 1) {
          const singleValue = accessory.value_list[0];
          if (!accessory.family.includes("Z")) {
            if (singleValue.value) {
              singleValue.value = "Yes";
              accessory.value_list.push({
                value: "No",
                price: 0,
                active: true,
                is_gross: false,
              });
            }
          } else {
            // singleValue.value = 'Yes'
            // accessory.value = singleValue;
          }
        }

        // RICORDARSI di SALVARE E PASSARE SEMPRE ACTION MANDATORY QUANDO SI SALVA LA CONFIGURAZIONE
        if (accessory.family.includes("Z")) {
          accessory.action = "hide";
        }
      });

      setAccessories(mainResult.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getControlledValue = async () => {
    if (specSelected?.controlled_by_list.length > 0) {
      try {
        const controlledPositions = specSelected.controlled_by_list.map(
          (item) => item.position_controlled_by
        );
        const controlObjects = results.filter((e) =>
          controlledPositions.includes(e.position)
        );

        specSelected.controlled_by_list.forEach((item) => {
          const obj = controlObjects.find(
            (e) => e.position === item.position_controlled_by
          );
          if (obj) {
            item.value_control = obj.value.value;
            item.position_control = obj.position;
          }
        });

        const body = {
          rdre: params.rdre,
          size: params.size,
          position: specSelected.position,
          position_control_list: specSelected.controlled_by_list,
        };

        let resultData;
        if (
          whichRole === "Admin" ||
          whichRole === "Internal" ||
          whichRole === "VIP"
        ) {
          try {
            const adminResult = await axios.post(
              "position/GetPositionValueConfidential",
              body
            );
            resultData = adminResult.data;

          } catch (e) {
            console.log(e);
          }
        } else {
          const mainResult = await axios.post(
            "position/GetPositionValue",
            body
          );
          resultData = mainResult.data;
        }

        const updatedResults = specs.map((e) =>
          e === specSelected ? { ...e, value_list: resultData } : e
        );
        setResults(updatedResults);
        dispatch({ type: SET_SPECS, value: updatedResults });
      } catch (e) {
        console.log(e);
      }
    }
    if (specSelected?.control_list.length > 0) {
      const updatedResults = [...results];

      specSelected.control_list.forEach((controlItem) => {
        const positionToRemove = controlItem.position_control;

        updatedResults.forEach((resultItem, index) => {
          if (
            resultItem.position === positionToRemove &&
            "value" in resultItem
          ) {
            const updatedResultItem = { ...resultItem };
            delete updatedResultItem.value;
            updatedResults[index] = updatedResultItem;
          }
        });
      });

      setResults(updatedResults);
    }
  };


  const getAccessorieZ = async () => {
    const copyArray = [...accessories];
    // const zFamilies = copyArray.filter((e) => e.family.includes('Z'))
    let newArray = [];
    let obj = [];

    newArray = copyArray.map((item) => {
      return {
        family_control: item.family,
        accessory: item.accessory,
      };
    });

    if (valueSelected?.spec.accessory_control_list.length > 0) {
      let accessoryControlList = valueSelected?.spec.accessory_control_list;

      for (const x of accessoryControlList) {
        if (accessoryControlList && accessoryControlList.length > 0) {
          // accessoryControlList = newArray;
          for (const item of x.family_list) {
            for (const tt of newArray) {
              if (tt.family_control === item.family_control) {
                const body = {
                  rdre: params.rdre,
                  size: params.size,
                  from_position: valueSelected.spec.position,
                  from_value: valueSelected.value.value,
                  family: tt?.family_control,
                  accessory: tt?.accessory,
                };

                try {
                  const result = await axios.get(
                    "accessory/GetAccessoryAction",
                    { params: body }
                  );
                  // Fai qualcosa con il risultato restituito dalla chiamata axios

                  if (result) {
                    if (result?.data?.accessory) {
                      const obj = copyArray.find(
                        (e) =>
                          e.accessory === result.data.accessory &&
                          e.family === result.data.family
                      );

                      obj.action = result.data.action;
                      if (obj.value_list.length === 1) {
                        if (result.data.action === "mandatory") {
                          const singleValue = obj.value_list[0];
                          singleValue.value = "Yes";
                          obj.value = singleValue;
                        } else {
                          delete obj.value;
                        }
                      }
                    }
                  }
                } catch (error) {
                  console.error("Errore durante la richiesta axios:", error);
                  // Gestisci l'errore in qualche modo
                }
              }
            }
          }
          setAccessories(copyArray);
        }
      }
    }
  };

  const getModelCode = async () => {
    // return model code
    const slash = product?.slash_after_position;
    const dash = product?.dash_after_position;
    let model_code = "";
    results.forEach((element) => {
      model_code += element?.value?.value ? element?.value?.value : "%";

      if (element.position === slash) {
        model_code += "/";
      }

      if (element.position === dash) {
        model_code += "-";
      }
    });
    setModelCode(model_code);
  };

  const getPriceBase = async () => {
    if (
      (whichRole === "Admin") |
      (whichRole === "Internal") |
      (whichRole === "VIP")
    ) {
      if (modelCode) {
        const body = {
          rdre: params.rdre,
          size: params.size,
          generated_code: modelCode?.replace(/%/g, ""),
        };

        axios
          .get("price/GetPriceBase", { params: body })
          .then((response) => {
            if (response.data) {
              setBasePrice(response.data.price_base);
              dispatch({
                type: SET_BASE_PRICE,
                value: response.data.price_base,
              });
            } else {
              setBasePrice(0);
              dispatch({ type: SET_BASE_PRICE, value: 0 });
            }
          })
          .catch((error) => {
            setBasePrice(0);
            dispatch({ type: SET_BASE_PRICE, value: 0 });
          });
      }
    }
  };

  const getDiscountList = async () => {
    const body = {
      rdre: params.rdre,
      size: params.size,
    };

    try {
      const results = await axios.get("discount/GetDiscountOption", {
        params: body,
      });
      if (results.data) {
        setDiscountList(results.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const getDiscountForExternal = async () => {
    if (whichRole === "External") {
      try {
        // Esegui getModelCode() prima dell'axios
        if (modelCode) {
          const body = {
            rdre: params.rdre,
            size: params.size,
            type_code: modelCode?.replace(/%/g, ""),
            product_discount_list: results
              .filter((s) => s?.value)
              .map((s) => {
                return {
                  value: s.value.value,
                  position: s.position,
                };
              }),
            accessory_discount_list: accessories
              .filter((a) => a?.value)
              .map((a) => {
                return {
                  value: a.value.value,
                  family: a.family,
                  accessory: a.accessory,
                };
              }),
          };

          const result = await axios.post("discount/GetDiscount", body);
          if (result) {
            dispatch({ type: SET_FINAL_PRICE, value: result.data.final_price });
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getStorage = async () => {
    const body = {
      id_configuration: params.id_configuration,
    };
    try {
      const result =
        whichRole === "Admin" || whichRole === "Internal" || whichRole === "VIP"
          ? await axios.get("storage/getstorageconfidential", { params: body })
          : await axios.get("storage/getstorage/", { params: body });
      if (result?.data) {
        // setAccessories(result?.data.accessory_list)
        createDiscountObj(
          result?.data,
          dispatch,
          whichRole,
          setResults,
          setAccessories
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const skipAccessories = () => {
    const copyAccessories = [...accessories];
    copyAccessories.forEach((oggetto) => {
      const noObj = oggetto.value_list.find((e) => e.value === "No");
      oggetto.value = noObj;
    });

    setAccessories(copyAccessories);
    dispatch({ type: SET_ACCS, value: copyAccessories });
  };

  useEffect(() => {
    getPriceBase();
    if (params.currentView === "summary") {
      getDiscountForExternal();
    }
  }, [modelCode]);

  useEffect(() => {
    getModelCode();
  }, [results, product]);

  useEffect(() => {
    dispatch({ type: "RESET_ARCHIVE_INFO" });

    if (params.id_configuration) {
      getStorage();
    } else {
      if (results.length === 0) {
        getSpecsQuery(params, whichRole);
      }
      if (accessories.length === 0) {
        getAccessoryQuery(params, whichRole);
      }
    }

    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleScreenChange = (e) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addListener(handleScreenChange);

    // Pulizia dell'event listener al momento della disconnessione del componente
    return () => {
      mediaQuery.removeListener(handleScreenChange);
    };
  }, []);

  useEffect(() => {
    if (valueSelected) {
      const copyResults = [...specs];
      const obj = copyResults.find((e) => e === valueSelected.spec);
      if (obj !== -1) {
        obj.value = valueSelected.value;

        //accessori Z
        getAccessorieZ();
        setResults(copyResults);
        dispatch({ type: SET_SPECS, value: copyResults });
      }
    }

    if (accessorySelected && accessorySelected.acc) {
      const copyAccessories = [...accessories];
      const selectedAccessory = copyAccessories.find(
        (accessory) => accessory === accessorySelected.acc
      );

      if (selectedAccessory) {
        selectedAccessory.value = accessorySelected.value;

        if (selectedAccessory.accessory === "Painting") {
          const oppositeFamilies = {
            S6: "S7",
            S7: "S6",
            S4: "S5",
            S5: "S4",
          };

          const oppositeFamily = oppositeFamilies[selectedAccessory.family];

          if (oppositeFamily && accessorySelected.value.value === "Yes") {
            const oppositeObj = copyAccessories.find(
              (obj) => obj.family === oppositeFamily
            );

            if (oppositeObj) {
              const oppositeValue = oppositeObj.value_list.find(
                (e) => e.value === "No"
              );
              if (oppositeValue) {
                oppositeObj.value = { ...oppositeValue };
              }
            }
          }
        }

        setAccessories(copyAccessories);
        dispatch({ type: SET_ACCS, value: copyAccessories });
      }
    }
  }, [valueSelected, accessorySelected]);

  useEffect(() => {
    getControlledValue();
  }, [specSelected]);

  useEffect(() => {
    document.querySelector("#root").scrollTo(0, 0);
    if (params.currentView === "discount" && country !== "GLOBAL") {
      getDiscountList();
      setReadOnly(false);
    }
    if (params.currentView === "summary") {
      getDiscountForExternal();
      setReadOnly(true);
      setShowSummaryHeader(true);
    }
    if (params.currentView === "edit") {
      setReadOnly(false);
    }
    if (!params.currentView) {
      setReadOnly(false);
    }
    if (params.currentView !== "summary") {
      setShowSummaryHeader(false);
    }
  }, [params]);

  return (
    <>
      {results?.length > 0 ? (
        <div
          style={
            isMobile
              ? { width: "calc(100% - 20px)", margin: "80px 10px" }
              : readOnly || whichRole === "External"
                ? { width: "calc(100% - 160px)" }
                : {}
          }
          className="wrapper"
        >
          <ManualPriceDrawer
            isMobile={isMobile}
            setValueSelected={setValueSelected}
            open={
              openModal &&
              product?.size_list?.find((e) => e.size === product.size)
                .in_datasheet
            }
            data={valueSelected}
            onClose={() => {
              setOpenModal(false);
            }}
          />

          <ModelCodeBar
            isMobile={isMobile}
            setOpenPriceDrawer={setOpenPriceDrawer}
            country={country}
            configuration={configuration}
            isOwner={user === archiveInfo?.user_created_on}
            specs={results}
            accessories={accessories}
            whichRole={whichRole}
            params={params}
            mergedResults={[...results, ...accessories]}
            modelCode={modelCode}
          />

          {showSummaryHeader && (
            <SummaryHeader
              isMobile={isMobile}
              project={project}
              archiveInfo={archiveInfo}
              model_code={modelCode}
              product={product}
              params={params}
              finalPrice={calculatedDiscount?.finalPrice || finalPrice}
            />
          )}
          {(!params.currentView ||
            params.currentView === "summary" ||
            params.currentView === "edit") && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TitleBox
                    isMobile={isMobile}
                    style={
                      isMobile ? { marginRight: "8px", fontSize: "10px" } : {}
                    }
                    title={"Product configuration"}
                  />
                  <div>
                    <DatasheetLegend />
                    <Button
                      color="secondary"
                      variant="contained"
                      startIcon={<DescriptionOutlined />}
                      size={mq_xs ? "small" : "medium"}
                      onClick={openMediaDirectory}
                    >
                      {params.rdre.substring(0, 5)}
                    </Button>
                  </div>
                </div>
                {specs?.map((spec, index) => {
                  return (
                    <Spec
                      isMobile={isMobile}
                      readonly={readOnly}
                      setOpenModal={setOpenModal}
                      setSpecSelected={setSpecSelected}
                      setValueSelected={setValueSelected}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                      index={index}
                      key={index}
                      spec={spec}
                    />
                  );
                })}
                <SpecialField
                  readonly={readOnly}
                  isSpecialSelected={results.some(
                    (obj) => obj.value && obj.value.is_special === true
                  )}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TitleBox isMobile={isMobile} title={"Accessories"} />
                  <div>
                    <Button
                      color="secondary"
                      variant="contained"
                      size={mq_xs ? "small" : "medium"}
                      onClick={skipAccessories}
                      startIcon={<BlockOutlined />}
                      disabled={readOnly}
                    >
                      Skip
                    </Button>
                  </div>
                </div>
                <div style={{ marginBottom: "30px" }}>
                  {accessories.map((acc, index) => {
                    return (
                      <Accessory
                        isMobile={isMobile}
                        readonly={readOnly}
                        setAccessorySelected={setAccessorySelected}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                        index={index}
                        key={index}
                        acc={acc}
                      />
                    );
                  })}
                </div>
              </>
            )}
          {whichRole !== "External" &&
            (params.currentView === "discount" || readOnly) && (
              <DiscountPage
                isMobile={isMobile}
                country={country}
                whichRole={whichRole}
                readonly={readOnly}
                data={discountList}
                modelCode={modelCode}
                basePrice={basePrice}
                params={params}
                results={results}
                accessories={accessories}
                mergedResults={[...results, ...accessories]}
              />
            )}

            {

                whichRole !== "External" &&
                // (params.currentView === "schede" || readOnly) && (
                //   <SchedeTaratura />
                // )}
                (params.currentView === "schede" || readOnly) &&
                (params.rdre === "92004" || params.rdre === "92003") && (
                  <SchedeTaratura params={params} readOnly={readOnly} />
                )
            

              }

          <PriceDrawer
            isMobile={isMobile}
            openPriceDrawer={openPriceDrawer}
            setOpenPriceDrawer={setOpenPriceDrawer}
            calculatedDiscount={calculatedDiscount}
            show={!readOnly}
            currentView={params.currentView}
            basePrice={basePrice}
            whichRole={whichRole}
            results={results}
            mergedResults={[...results, ...accessories]}
          />
        </div>
      ) : (
        <LoadingBar />
      )}
    </>
  );
};

export default Detail;
