import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core";
import { formatPercentage, formatPriceValue } from "../../../../../../../utils/Utils";
import DialogOptionList from "../DialogOptionList";
import { ContactSupportOutlined, SearchOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import TitleBox from "../../../../../../../components/TitleBox";
import CustomerInfoBelgium from "../../../../../../../components/Configuration/Discount/Customer/CustomerInfoBelgium/CustomerInfoBelgium";
import CustomerFlagDirect from "../../../../../../../components/Configuration/Discount/CustomerFlagDirect/CustomerFlagDirect";
import CustomerBox from "../CustomerBox";
import { useAxios } from "../../../../../../../utils/useAxios";
import CustomerType from "../CustomerType";
import FlagDirect from "../FlagDirect";





const DiscountBox = ({ title, type, data, setOptionSelected, options, isNeeded, readonly, validation, params, country, isMobile, customerTypeOptions }) => {

    const discountInfo = useSelector(state => {
        return state?.configuration?.discountInfo
    });
    const customCustomer = useSelector(state => {
        return state?.configuration?.customCustomer
    });
    const customerType = useSelector(state => {
        return state?.configuration?.discount?.customer_type
    });



    const axios = useAxios();

    const [activeId, setActiveId] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogData, setDialogData] = useState();
    const [optionValue, setOptionValue] = useState(null)
    const [customerClassValue, setCustomerClassValue] = useState(null)
    const [isCustom, setIsCustom] = useState(false);
    const [isForClass, setIsForClass] = useState(false);



    useEffect(() => {
        if (customCustomer) {
            setCustomerClassValue(customCustomer?.manualDiscountClass)
            setIsCustom(true);

        }
        setOptionValue(discountInfo?.find(e => e.type === type)?.optionValue)




    }, [discountInfo, customCustomer]);




    const addCustomOption = async () => {

        if (type === "customer") {

            setIsCustom(true);

            const body = {
                rdre: params?.rdre,
                size: params?.size
            }


            try {

                const result = await axios.get('discount/GetDiscountClass', { params: body });
                if (result?.data) {
                    setDialogData(result.data)




                }
            } catch (e) {
                console.log(e);
                // }              

            }

        }



    }




    return (
        <>
            <Grid className="discount-box" item xs={12} md={6}>
                <TitleBox isMobile={isMobile} title={title} />
                <div className="info-box">
                    <div onClick={() => {
                        if (!readonly) {
                            setOpenDialog(true);
                            setDialogData(options)
                            setIsForClass(false);
                        }
                    }} className="search-box">
                        <SearchOutlined style={{ marginRight: '8px', color: 'rgb(0, 43, 73)' }} color="inherit" />
                        <p style={optionValue || (type === 'branch_manager' && !isNeeded) ? { color: 'rgb(0, 43, 73)' } : {}}>{optionValue ? optionValue : (type === 'branch_manager' && !isNeeded) ? 'Not needed' : 'Choose ' + title}</p>
                    </div>
                    {(country !== 'IT' && type === "customer") && <>
                        <CustomerType customerType={customerType?.customer_type} onClick={() => { setOpenDialog(true); setDialogData(customerTypeOptions) }} readonly={readonly} />
                        <FlagDirect readonly={readonly} />
                    </>}

                    {(type === "customer" && isCustom && country === 'IT') && <div onClick={() => {
                        if (!readonly) {
                            setOpenDialog(true);
                            addCustomOption();
                            setIsForClass(true);
                        }
                    }} className="search-box">
                        <SearchOutlined style={{ marginRight: '8px', color: 'rgb(0, 43, 73)' }} color="inherit" />
                        <p style={customerClassValue ? { color: 'rgb(0, 43, 73)' } : {}}>{customerClassValue ? customerClassValue : 'Choose Customer Class '}</p>
                    </div>}

                    <Grid style={{ borderBottom: '1px solid #bfcad1' }} container spacing={2}>
                        {(type === 'customer') && <>
                            <CustomerBox data={data} country={country} readonly={readonly} />
                        </>}
                        {(type === 'sales_engineer') ? country === 'IT' ? <>

                            <Grid item xs={4} className="custom-border  border-r-none">
                                <p>Top Customer Discount</p>
                            </Grid>
                            <Grid item xs={4} className="custom-border  border-r-none">
                                {formatPercentage(data?.top_sales_engineer_limit_discount)}
                            </Grid>
                            <Grid item xs={4} className="custom-border">
                                {formatPriceValue(data?.top_sales_engineer_limit_price)}
                            </Grid>
                            <Grid item xs={4} className="custom-border  border-r-none">
                                <p>Cluster Limit</p>
                            </Grid>
                            <Grid item xs={4} className="custom-border  border-r-none">
                                {formatPercentage(data?.cluster_manager_limit_discount)}
                            </Grid>
                            <Grid item xs={4} className="custom-border">
                                {formatPriceValue(data?.cluster_manager_limit_price)}
                            </Grid>
                            <Grid item xs={4} className="custom-border  border-r-none">
                                <p>SLM1 o PM Limit</p>
                            </Grid>
                            <Grid item xs={4} className="custom-border  border-r-none">
                                {formatPercentage(data?.product_manager_limit_discount)}
                            </Grid>
                            <Grid item xs={4} className="custom-border">
                                {formatPriceValue(data?.product_manager_limit_price)}
                            </Grid>
                        </> : <>
                            <Grid item xs={4} className="custom-border  border-r-none">
                                <p>CU Maximum Price</p>
                            </Grid>
                            <Grid item xs={4} className="custom-border  border-r-none">
                                {formatPercentage(data?.cu_maximum_discount)}
                            </Grid>
                            <Grid item xs={4} className="custom-border">
                                {formatPriceValue(data?.cu_maximum_price)}
                            </Grid>
                        </> : <></>}

                    </Grid>
                </div>
            </Grid >
            <DialogOptionList isForClass={isForClass} customerClassValue={customerClassValue} type={type} setIsCustom={setIsCustom} isCustom={isCustom} setCustomerClassValue={setCustomerClassValue} setOptionValue={setOptionValue} activeId={activeId} setActiveId={setActiveId} setOptionSelected={setOptionSelected} handleClose={() => { setOpenDialog(false) }} open={openDialog} data={dialogData} onClick={() => { addCustomOption() }} />
        </>

    )
}

export default DiscountBox