import React from "react";
import {
  Document,
  Page,
  Image,
  View,
  Text,
  StyleSheet,
  usePDF,
} from "@react-pdf/renderer";
import PDFHeaderData from "./PDFHeaderData";
import PDFConfiguration from "./PDFConfiguration";
import RexrothLogo from "../../assets/media/Rexroth-Logo_RGB_M.png";
import PDFCalibration from "./PDFCalibration";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  logo: {
    width: "20%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 6,
    paddingBottom: 6,
  },

  specstitle: {
    paddingRight: "20px",
    width: "90%",
    borderStyle: "solid 1pt",
    borderColor: "black",
  },
  specsdescription: {
    width: "90%",
    color: "#5a7c91",
    borderStyle: "solid 1pt",
    borderColor: "blue",
  },
  today: {
    position: "absolute",
    right: 0,
    top: 0,
    fontSize: 10,
  },
  title: {
    marginTop: "8",
    marginBottom: "14",
    textAlign: "center",
    fontSize: "11",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginBottom: "4px",
  },
  flexSignature: {
    display: "flex",
    alignItems: "left",
    flexDirection: "row",
    marginBottom: "8px",
    borderTop: "solid 3px",
    color: "RGB(255,0,0)",
  },
  rowInformation: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    fontSize: 10,
  },
  subTitle: {
    marginTop: 14,
    fontSize: 12,
  },
  divider: {
    marginTop: 6,
  },
  sizeHalf: {
    width: "50%",
  },
  detailsWrapper: {
    fontSize: 10,
    marginBottom: "30px",
  },
  specValue: {
    fontSize: 8,
  },
  downloadBtn: {
    textDecoration: "none",
    color: "inherit",
    fontFamily: "inherit",
    height: 24,
  },
});

const dateOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  timeZone: "Europe/Rome",
};
const MyPDFCustomer = ({ data, modelCode, whichRole, country }) => {
  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <View>
          <Image src={RexrothLogo} style={styles.logo} />
          <Text style={styles.today}>
            {new Intl.DateTimeFormat("en-GB", dateOptions).format(new Date())}
          </Text>
        </View>
        <View style={styles.title}>
          <Text>CLASSIFICATION: {modelCode?.replace(/%/g, "")}</Text>
        </View>
        <PDFHeaderData
          data={data}
          model_code={modelCode}
          whichRole={whichRole}
          country={country}
        />
        <PDFConfiguration
          data={data}
          model_code={modelCode}
          whichRole={whichRole}
          country={country}
        />
        {data?.schedeTaratura && (
          <PDFCalibration
            data={data.schedeTaratura}
            model_code={modelCode}
            whichRole={whichRole}
            country={country}
          />
        )}
      </Page>
    </Document>
  );
};

export default MyPDFCustomer;
