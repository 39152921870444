import { SET_ACCS, SET_APPLICATION_INFO_FINAL, SET_ARCHIVE_INFO, SET_MANUAL_DISCOUNT_CLASS, SET_SPECIAL_REQUEST, SET_CALCULATED_DISCOUNT, SET_DISCOUNT, SET_DISCOUNT_INFO, SET_SPECS, UPDATE_PRODUCT_SELECTED, SET_PROJECT, SET_SCHEDE_TARATURA } from "../store/actions/actions"

export const createDiscountObj = (data, dispatch, whichRole, setResults, setAccessories) => {

    if (whichRole != 'External') {
        const discountInfo = [{
            type: data?.discount?.customer ? 'customer' : null,
            optionSelected: {
                id_customer: data?.discount?.id_customer,
                customer: data?.discount?.customer,
            },
            optionValue: data?.discount?.customer
        },
        {

            type: data?.discount?.sales_engineer ? 'sales_engineer' : null,
            optionSelected: {
                id_sales_engineer: data?.discount?.id_sales_engineer,
                sales_engineer: data?.discount?.sales_engineer,
            },
            optionValue: data?.discount?.sales_engineer
        },
        {

            type: data?.discount?.branch_manager ? 'branch_manager' : null,
            optionSelected: {
                id_branch_manager: data?.discount?.id_branch_manager,
                branch_manager: data?.discount?.branch_manager,
            },
            optionValue: data?.discount?.branch_manager
        },
        {

            type: data?.discount?.customer && !data.discount?.id_customer ? 'erp_discount_class' : null,
            optionSelected: {
                id_customer: data?.discount?.id_customer,
                customer: data?.discount?.customer,
            },
            optionValue: data?.discount?.erp_discount_class
        },
        ]
        dispatch({ type: SET_DISCOUNT_INFO, value: discountInfo })

        const calculatedDiscount = {
            final_discount: data?.discount?.final_discount,
            final_discount_cluster: data?.discount?.final_discount_cluster,
            final_discount_grid: data?.discount?.final_discount_grid,
            extra_final_discount: data?.discount?.extra_final_discount,
            extra_final_discount_cluster: data?.discount?.extra_final_discount_cluster,
            extra_final_discount_grid: data?.discount?.extra_final_discount_grid,
            finalPrice: data?.configuration?.final_price,
            sales_net_price: data?.discount?.sales_net_price,
            sales_manual_discount: data?.discount?.sales_manual_discount,
            extra_discounted_price: data?.discount?.extra_discounted_price,
            extra_discount: data?.discount?.extra_discount,

        }

        dispatch({ type: SET_CALCULATED_DISCOUNT, value: calculatedDiscount })
        const applicationInfo = {
            is_military: data?.discount?.is_military,
            is_nuclear: data?.discount?.is_nuclear,
            is_aerospace: data?.discount?.is_aerospace,
            existing_code: data?.discount?.existing_code,
            min_order_qty: data?.discount?.min_order_qty,
            est_annual_qty: data?.discount?.est_annual_qty
        }
        dispatch({ type: SET_APPLICATION_INFO_FINAL, value: applicationInfo })
        dispatch({ type: SET_DISCOUNT, value: data?.discount })
    }
    dispatch({ type: SET_SPECS, value: data?.position_list })
    const preparedAccessoriesList = data?.accessory_list.filter(e => e.value)
    preparedAccessoriesList.forEach(accessory => {
        if (accessory.value_list.length === 1) {
            const singleValue = accessory.value_list[0];
            if (!accessory.family.includes('Z')) {
                if (singleValue.value) {
                    singleValue.value = 'Yes';
                    accessory.value_list.push({ value: 'No', price: 0, active: true, is_gross: false });
                }
            } else {
                // singleValue.value = 'Yes'
                // accessory.value = singleValue;
            }
        }

    });
    dispatch({ type: SET_ACCS, value: preparedAccessoriesList })
    setAccessories(preparedAccessoriesList);
    setResults(data?.position_list);
    dispatch({ type: UPDATE_PRODUCT_SELECTED, value: data?.configuration })
    dispatch({ type: SET_ARCHIVE_INFO, value: data?.configuration })
    dispatch({ type: SET_PROJECT, id: data?.project?.id, value: data?.project?.name })
    dispatch({ type: SET_SPECIAL_REQUEST, value: data?.configuration?.special_request })
    dispatch({ type: SET_SCHEDE_TARATURA, value: data?.calibration })

    if (data?.discount?.customer && !data?.discount?.id_customer) {

        const customObj = {
            customer: data?.discount?.customer,
            manualDiscountClass: data?.discount?.manual_discount_class
        }
        dispatch({ type: SET_MANUAL_DISCOUNT_CLASS, value: customObj });
    }
}