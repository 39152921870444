import React from "react";
import { Text, View, StyleSheet, Svg, Line } from "@react-pdf/renderer";
import { createStyles } from "@material-ui/core";

const styles = StyleSheet.create({
  divider: {
    marginTop: 8,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid #EEE",
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 10,
  },
  title: {
    margin: "8px 0",
    textAlign: "center",
    fontSize: "14",
  },
  sectionTitle: {
    margin: "8px 0",
    textAlign: "center",
    fontSize: "12",
  },
  specstitle: {
    paddingRight: "20px",
    width: "30%",
  },
  specsdescription: {
    width: "70%",
    color: "#5a7c91",
  },
  lastRow: {
    borderBottom: "1px solid #EEE",
  },
});

const PDFCalibration = ({ data }) => {
  /**
   *
   * @param label input field name
   * @returns resultLabel --> the result of the label elaboration which will be used as input label
   */
  const getLabel = (label) => {
    let resultLabel = "";

    if (label.includes("_")) {
      // divide the string into n string array
      const labelSplitted = label.split("_");

      // get the first letter uppercase
      const firstWordSplitted = labelSplitted[0].split("");
      firstWordSplitted[0] = firstWordSplitted[0].toUpperCase();
      labelSplitted[0] = firstWordSplitted.join("");

      // get the final label joining the labelSplitted values divided by a space
      resultLabel = labelSplitted.join(" ");
    } else {
      // get the first letter uppercase
      const labelSplitted = label.split("");
      labelSplitted[0] = labelSplitted[0].toUpperCase();

      resultLabel = labelSplitted.join("");
    }

    return resultLabel;
  };

  return (
    <>
      <View style={styles.divider}>
        <Svg width="100%" height="4">
          <Line
            x1="0"
            x2="1000"
            y1="2"
            y2="2"
            strokeWidth="1"
            stroke="#002b49"
          />
        </Svg>
      </View>

      {/*  */}
      <View style={styles.title}>
        <Text>Calibration Sheet</Text>
      </View>

      {/* calibration object mapping */}
      {data &&
        Object.keys(data).map((key, index) => {
          return (
            <React.Fragment key={index}>
              {/* section title */}
              <View style={styles.sectionTitle}>
                <Text>{getLabel(key)}</Text>
              </View>

              {/* section value map */}
              {data[key] &&
                Object.keys(data[key]).map(
                  (innerKey, innerIndex, innerKeys) => {
                    return (
                      <React.Fragment key={innerIndex}>
                        <View
                          style={
                            innerIndex === innerKeys.length - 1
                              ? [styles.row, styles.lastRow]
                              : styles.row
                          }
                        >
                          <Text style={styles.specstitle}>
                            {getLabel(innerKey) + ":"}
                          </Text>

                          <Text style={styles.specsdescription}>
                            {typeof data[key][innerKey] === "object" &&
                            data[key][innerKey] != null
                              ? data[key][innerKey].value
                              : data[key][innerKey]}
                          </Text>
                        </View>
                      </React.Fragment>
                    );
                  }
                )}
            </React.Fragment>
          );
        })}
    </>
  );
};

export default PDFCalibration;
