import { AppBar, Box, Container, Button, Hidden, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { CancelOutlined, DoneAllOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CLEAR_MATERIAL_SELECTED } from '../../../store/actions/ProductSelector/actions';
import { useMqXS } from '../../../utils/Utils';
import ProductSelector from '../../ProductSelector/ProductSelector';
import CheckPriceButton from '../CheckPriceButton/CheckPriceButton.jsx';
import './ModelCodeBar.css'
import ButtonSummary from '../Discount/ButtonSummary/ButtonSummary';
import DialogSaveConfiguration from '../Summary/DialogSaveConfiguration/DialogSaveConfiguration';
import CopyEditButton from '../../../pages/Configuration/Detail/components/CopyEditButton';
import RequestQuotationForm from '../Summary/RequestQuotationForm/RequestQuotationForm';
import BackButton from '../../../pages/Configuration/Detail/components/BackButton';
import DeleteButton from '../../../pages/Configuration/Detail/components/DeleteButton';
import PDFCustomer from '../../PDF/PDFCustomer';
import PDFInternal from '../../PDF/PDFInternal';
import PDFOtherCountry from '../../PDF/PDFOtherCountry';
import MobileBottomBarLayout from '../../../pages/Configuration/Detail/components/MobileBottomBarLayout';

const useStyles = makeStyles(theme => ({
    appbar: {
        top: theme.spacing(8),
        [theme.breakpoints.down("xs")]: {
            top: theme.spacing(7)
        },
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.standard.main}`
    },
    DoneAllOutlined: {
        color: theme.palette.success.main
    }
}));

const ModelCodeBar = ({ modelCode, mergedResults, params, whichRole, specs, accessories, isOwner, configuration, country, setOpenPriceDrawer, isMobile }) => {

    const styles = useStyles();
    const dispatch = useDispatch();
    const mq_xs = useMqXS();

    const clearProductSelected = () => dispatch({ type: CLEAR_MATERIAL_SELECTED });

    const productSelected = useSelector(state => {
        return state.productSelector.materialSelected.description
    });

    const handleDelete = () => {
        clearProductSelected();
    }

    return (
      <AppBar position="fixed" elevation={0} classes={{ root: styles.appbar }}>
        <Toolbar>
          <Container maxWidth="lg" disableGutters={mq_xs}>
            <Box
              className="product-selector-container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent:
                  params.currentView === "summary"
                    ? "space-between"
                    : "flex-start",
              }}
            >
              <Hidden smDown>
                <BackButton whichRole={whichRole} params={params} />
              </Hidden>
              {params?.currentView !== "summary" ? (
                productSelected ? (
                  <>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          variant={mq_xs ? "body1" : "h6"}
                          color="primary"
                        >
                          {modelCode?.replace(/%/g, "")}
                        </Typography>
                        {modelCode?.replace(/%/g, "") === productSelected ? (
                          <Tooltip title="Codes verified" placement="bottom">
                            <DoneAllOutlined
                              classes={{ root: styles.DoneAllOutlined }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title="The codes cannot be verified"
                            placement="bottom"
                          >
                            <CancelOutlined color="error" />
                          </Tooltip>
                        )}
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          variant={mq_xs ? "body1" : "h6"}
                          color="primary"
                        >
                          {productSelected}
                        </Typography>
                        <IconButton size="small" onClick={handleDelete}>
                          <CancelOutlined />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box ml={"auto"}>
                      <ProductSelector modelCode={modelCode} />
                    </Box>
                  </>
                ) : (
                  <>
                    {/* se NON ho selezionato nessun prodotto dal product selector */}
                    <Typography
                      variant={mq_xs ? "body1" : "h6"}
                      color="primary"
                    >
                      {modelCode?.replace(/%/g, "")}
                    </Typography>
                    <Box ml={"auto"}>
                      <ProductSelector modelCode={modelCode} />
                    </Box>
                  </>
                )
              ) : (
                <></>
              )}
              <MobileBottomBarLayout isMobile={isMobile}>
                {isMobile && params.currentView !== "summary" && (
                  <Button
                    style={{ marginRight: "8px" }}
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      setOpenPriceDrawer(true);
                    }}
                  >
                    Price Info
                  </Button>
                )}
                {params?.currentView === "discount" && (
                  <ButtonSummary
                    params={params}
                    isValidExistingCode={
                      configuration?.existingCode === ""
                        ? true
                        : configuration?.existingCode.length === 10 &&
                          configuration?.existingCode.startsWith("R")
                    }
                  />
                )}
                {(!params.currentView || params.currentView === "edit") && (
                  <CheckPriceButton
                    isMobile={isMobile}
                    isSpecialField={specs?.some(
                      (obj) => obj.value && obj.value.is_special === true
                    )}
                    specialSelected={configuration?.specialSelected}
                    role={whichRole}
                    params={params}
                    mergedResults={mergedResults}
                  />
                )}
                {params.currentView === "summary" && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {params.fromArchive && (
                      <>
                        {(whichRole === "Admin" ||
                          whichRole === "Internal" ||
                          whichRole === "VIP" ||
                          whichRole === "External") && (
                          <>
                            <PDFCustomer
                              data={configuration}
                              model_code={modelCode}
                              whichRole={whichRole}
                            />
                          </>
                        )}
                        {(whichRole === "Admin" ||
                          whichRole === "Internal" ||
                          whichRole === "VIP") &&
                          country === "IT" && (
                            <>
                              <PDFInternal
                                specs={specs}
                                accessories={accessories}
                                data={configuration}
                                model_code={modelCode}
                                whichRole={whichRole}
                                country={country}
                              />
                            </>
                          )}
                        {(whichRole === "Admin" ||
                          whichRole === "Internal" ||
                          whichRole === "VIP") &&
                          country !== "IT" && (
                            <>
                              <PDFOtherCountry
                                specs={specs}
                                accessories={accessories}
                                data={configuration}
                                model_code={modelCode}
                                whichRole={whichRole}
                                country={country}
                              />
                            </>
                          )}
                      </>
                    )}

                    {params.id_configuration && params.fromArchive && (
                      <>
                        <CopyEditButton type={"copy"} params={params} />
                        <CopyEditButton
                          editable={isOwner}
                          type={"edit"}
                          params={params}
                        />{" "}
                        <DeleteButton params={params} isOwner={isOwner} />
                      </>
                    )}
                    {!params.fromArchive && (
                      <>
                        {" "}
                        <DialogSaveConfiguration
                          configuration={configuration}
                          country={country}
                          params={params}
                          specs={specs}
                          accessories={accessories}
                          whichRole={whichRole}
                          modelCode={modelCode}
                        />
                        <RequestQuotationForm
                          params={params}
                          configuration={configuration}
                          whichRole={whichRole}
                        />
                      </>
                    )}
                  </div>
                )}
              </MobileBottomBarLayout>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    );

}

export default ModelCodeBar;