
import { Box, ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { useState, useRef, useEffect } from "react";
import OptionSymbol from "../../../../../components/UI/OptionSymbol/OptionSymbol";
import { useRoleDesc } from "../../../../../utils/useRoleDesc";



const OptionsMenu = ({ data, setActiveIndex, setValueSelected, setOpenModal, isMobile }) => {



    const whichRoles = useRoleDesc();
    const menuRef = useRef(null);


    const categorizedOptions = data.value_list.reduce((acc, option) => {
        if (option.is_valid === true) {
            if (option.in_phase_out === true) {
                acc.phaseOutList.push(option);
            } else {
                if (option.in_datasheet === true) {
                    acc.standardList.push(option);
                } else {
                    acc.internalList.push(option);
                }
            }
        } else {
            if (option.in_datasheet === true) {
                acc.standardOutList.push(option);
            } else {
                acc.internalOutList.push(option);
            }
        }
        return acc;
    }, {
        standardList: [],
        internalList: [],
        standardOutList: [],
        internalOutList: [],
        phaseOutList: [],
    });

    const { standardList, internalList, standardOutList, internalOutList, phaseOutList } = categorizedOptions;
    const allOptions = [...standardList, ...internalList, ...standardOutList, ...internalOutList, ...phaseOutList];
    if (standardList.length > 0) standardList[0].title = 'Standard catalog ';
    if (internalList.length > 0) internalList[0].title = 'Internal catalog';
    if (standardOutList.length > 0) standardOutList[0].title = 'Out of standard catalog';
    // if (internalOutList.length > 0) internalOutList[0].title = 'Out of internal catalog';
    if (internalOutList.length > 0) internalOutList[0].title = 'Out of internal catalog';
    if (phaseOutList.length > 0) phaseOutList[0].title = 'Phase Out';

    // Crea un oggetto per mappare l'indice di ordinamento delle diverse liste
    allOptions.sort((a, b) => {
        const orderMap = {
            standardList: 0,
            internalList: 1,
            standardOutList: 2,
            internalOutList: 3,
            phaseOutList: 4,
        };

        const getTypeOrder = (item) => {
            if (standardList.includes(item)) return 'standardList';
            if (internalList.includes(item)) return 'internalList';
            if (standardOutList.includes(item)) return 'standardOutList';
            if (internalOutList.includes(item)) return 'internalOutList';
            if (phaseOutList.includes(item)) return 'phaseOutList';
            return '';
        };

        const typeA = getTypeOrder(a);
        const typeB = getTypeOrder(b);

        return orderMap[typeA] - orderMap[typeB];
    });




    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            // Se il clic è avvenuto al di fuori del menu, chiudi il menu
            setActiveIndex(900);
        }
    };



    useEffect(() => {
        // Aggiungi un event listener per il clic al di fuori del menu
        document.addEventListener('mousedown', handleClickOutside);

        // Pulisci l'event listener quando il componente viene smontato
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    console.log('ALLOPTION', allOptions, data);

    return (

        <div className="menu" ref={menuRef} style={{ width: isMobile ? '100%' : '', position: 'absolute', top: '-45px', left: isMobile ? '0' : '-45px', backgroundColor: 'white', border: '1px solid black' }} >

            {allOptions.map((value, index) => {
                if (value.is_valid) {
                    return (
                        <div key={index}>
                            <p style={{ color: '#00ccff' }}>{value?.title}</p>
                            {value?.title && <hr style={{ border: 'none', height: '1px', backgroundColor: 'rgb(191, 202, 209)' }} />}
                            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => {
                                setActiveIndex();
                                setValueSelected({ 'value': value, 'spec': data });
                                if (value?.in_datasheet === false) {
                                    setOpenModal(true)
                                }

                            }} >
                                <OptionSymbol type={value.type} is_valid={value.is_valid} />
                                {value.value} &middot; {value.value_desc} &middot; {value?.value_details}
                            </div>
                        </div>
                    )
                }

            })}
        </div>
    );
};

export default OptionsMenu;
