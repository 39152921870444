export const SET_DISCOUNT = "SET_DISCOUNT";
export const SET_EXISTING_CODE = "SET_EXISTING_CODE";
export const SET_ARCHIVE_INFO = "SET_ARCHIVE_INFO";
export const RESET_ARCHIVE_INFO = "RESET_ARCHIVE_INFO";
export const SET_SPECIAL_REQUEST = "SET_SPECIAL_REQUEST";
export const RESET_SPECIAL_REQUEST = "RESET_SPECIAL_REQUEST";
export const SET_DISCOUNT_INFO = "SET_DISCOUNT_INFO";
export const SET_DISCOUNT_PRICES = "SET_DISCOUNT_PRICES";
export const SET_SCHEDE_TARATURA = "SET_SCHEDE_TARATURA";
export const CLEAR_SCHEDE_TARATURA = "CLEAR_SCHEDE_TARATURA";
export const SET_CALCULATED_DISCOUNT = "SET_CALCULATED_DISCOUNT";
export const SET_APPLICATION_INFO = "SET_APPLICATION_INFO";
export const SET_APPLICATION_INFO_FINAL = "SET_APPLICATION_INFO_FINAL";
export const SET_SPECS = "SET_SPECS";
export const SET_ACCS = "SET_ACCS";
export const SET_ARRAY = "SET_ARRAY";
export const SET_PRICE_ERROR = "SET_PRICE_ERROR";
export const SET_FINAL_PRICE = 'SET_FINAL_PRICE';
export const SET_FINAL_NET_PRICE = 'SET_FINAL_NET_PRICE';
export const RESET_SPECS_LIST = 'RESET_SPECS_LIST';
export const RESET_ACC_LIST = 'RESET_ACC_LIST';
export const RESET_PRODUCT_SELECED = 'RESET_PRODUCT_SELECED';
export const SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST';
export const UPDATE_PRODUCT_SELECTED = 'UPDATE_PRODUCT_SELECTED';
export const SET_ACCESSORIES_LIST = 'SET_ACCESSORIES_LIST';
export const SET_ACCESSORIES_SELECTED_LIST = 'SET_ACCESSORIES_SELECTED_LIST';
export const UPDATE_ACCESSORY_SELECTED = 'UPDATE_ACCESSORY_SELECTED';
export const RESET_ACCESSORIES_SELECTED = 'RESET_ACCESSORIES_SELECTED';
export const SET_SPECIAL_APPLICATION = 'SET_SPECIAL_APPLICATION';
export const SET_ADDITIONAL_INFO = 'SET_ADDITIONAL_INFO';
export const SET_CUSTOMERS_LIST = 'SET_CUSTOMERS_LIST';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_CUSTOMER_DISCOUNT = 'SET_CUSTOMER_DISCOUNT';
export const SET_SALES_ENGINEER_LIST = 'SET_SALES_ENGINEER_LIST';
export const SET_SALES_ENGINEER = 'SET_SALES_ENGINEER';
export const SET_BRANCH_MANAGER_LIST = 'SET_BRANCH_MANAGER_LIST';
export const SET_BRANCH_MANAGER = 'SET_BRANCH_MANAGER';
export const UPDATE_SALES_NET_PRICE = 'UPDATE_SALES_NET_PRICE';
export const UPDATE_STANDARD_DISCOUNT = 'UPDATE_STANDARD_DISCOUNT';
export const UPDATE_EXTRA_DISCOUNTED_PRICE = 'UPDATE_EXTRA_DISCOUNTED_PRICE';
export const UPDATE_EXTRA_DISCOUNT = 'UPDATE_EXTRA_DISCOUNT';
export const RESET_DISCOUNT = "RESET_DISCOUNT";
export const UPDATE_ADMIN_VIEW = "UPDATE_ADMIN_VIEW";
export const SET_PROJECTS_LIST = "SET_PROJECTS_LIST";
export const SET_PROJECT = "SET_PROJECT";
export const SET_CONFIGURATION_ID = "SET_CONFIGURATION_ID";
export const SET_CONFIGURATION_NAME = "SET_CONFIGURATION_NAME";
export const SET_ARCHIVE_CONFIG_DATA = 'SET_ARCHIVE_CONFIG_DATA';
export const RESET_CONFIGURATION_NAME = 'RESET_CONFIGURATION_NAME';
export const RESET_COMPLETE_CONFIG = 'RESET_COMPLETE_CONFIG';
export const SET_CONFIG_CURRENT_SECTION = 'SET_CONFIG_CURRENT_SECTION';
export const SET_BASE_PRICE = "SET_BASE_PRICE";
export const UPDATE_SPEC_SELECTED = "UPDATE_SPEC_SELECTED";
export const SET_PRODUCT_DATA_EDIT = "SET_PRODUCT_DATA_EDIT";
export const SET_MANUAL_DISCOUNT_CLASS_LIST = 'SET_MANUAL_DISCOUNT_CLASS_LIST';
export const SET_MANUAL_DISCOUNT_CLASS = 'SET_MANUAL_DISCOUNT_CLASS';
export const SET_CUSTOMER_TYPE_LIST = 'SET_CUSTOMER_TYPE_LIST';
export const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';
export const SET_CUSTOMER_DIRECT_FLAG = 'SET_CUSTOMER_DIRECT_FLAG';