import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowDropDownOutlined } from "@material-ui/icons";
import OptionsMenu from "../OptionsMenu";
import OptionSymbol from "../../../../../components/UI/OptionSymbol/OptionSymbol";






const Spec = ({ spec, activeIndex, setActiveIndex, index, setValueSelected, setSpecSelected, setOpenModal, readonly, isMobile }) => {


    return (
        <>
            {!isMobile ? <Grid container style={{ border: '1px solid #bfcad1', backgroundColor: 'white', margin: '0 0 -1.5px 0', width: 'auto' }} spacing={2}>
                <Grid style={{ borderRight: '1px solid #bfcad1', padding: '8px', justifyContent: 'center', display: 'flex' }} item xs={1}>
                    <p> {spec.position}</p>
                </Grid>
                <Grid style={{ borderRight: '1px solid #bfcad1', padding: '8px 8px 8px 16px', display: 'flex', alignItems: 'center' }} item xs={3}>
                    <p style={{ margin: '0' }}>  {spec.position_desc}</p>
                </Grid>
                <Grid style={{ position: 'relative', display: 'flex', alignItems: 'center', padding: '8px 8px 8px 16px' }} item xs={8}>
                    <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: spec?.value?.value_desc ? '' : 'red', padding: '0px 10px' }} onClick={() => {
                        if (!readonly) {


                            setTimeout(() => {
                                setSpecSelected(spec);
                                setActiveIndex(index);
                            }, 1000); // 2000 millisecondi = 2 secondi
                        }
                    }} >{(spec?.value?.value_desc) ? (
                        <>
                            <OptionSymbol type={spec?.value?.type} />
                            <p style={{ margin: ' 0 0 0 10px' }}> &middot;</p>
                            <p style={{ margin: '0 0 0 10px' }}> {spec?.value?.value} </p>
                            <p style={{ margin: '0 10px 0 10px' }}>  &middot;</p>
                            <p style={{ margin: '0' }}>{spec?.value?.value_desc} {spec?.value?.value_details && ` · ${spec?.value?.value_details}`}</p>


                        </>
                    ) : (
                        'Choose an option'
                    )} {(spec.value_list.length > 1 || spec.controlled_by_list.length > 0) && <ArrowDropDownOutlined />}</div>
                    {(activeIndex === index && (spec.value_list.length > 1 || spec.controlled_by_list.length > 0)) && <OptionsMenu setActiveIndex={setActiveIndex} setOpenModal={setOpenModal} setValueSelected={setValueSelected} data={spec} />}

                </Grid>
            </Grid> : <div className="mobile-conf">
                <div> <p> {spec.position}</p></div>
                <div>  <p style={{ margin: '0' }}>  {spec.position_desc}</p></div>
                <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', color: spec?.value?.value_desc ? '' : 'red', padding: '0px 10px' }} onClick={() => {
                    if (!readonly) {
                        setSpecSelected(spec);

                        setTimeout(() => {
                            setActiveIndex(index);
                        }, 1000); // 2000 millisecondi = 2 secondi
                    }
                }} >{(spec?.value?.value_desc) ? (
                    <>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <OptionSymbol type={spec?.value?.type} />
                            <p style={{ margin: ' 0 0 0 10px' }}> &middot;</p>
                            <p style={{ margin: '0 0 0 10px' }}> {spec?.value?.value} </p>
                        </div>

                        <p style={{ margin: '0', textAlign: 'center' }}>{spec?.value?.value_desc} {spec?.value?.value_details && ` · ${spec?.value?.value_details}`}</p>


                    </>
                ) : (
                    'Choose an option'
                )} {(spec.value_list.length > 1 || spec.controlled_by_list.length > 0) && <div><ArrowDropDownOutlined /></div>}</div>
                {(activeIndex === index && (spec.value_list.length > 1 || spec.controlled_by_list.length > 0)) && <OptionsMenu isMobile={isMobile} setActiveIndex={setActiveIndex} setOpenModal={setOpenModal} setValueSelected={setValueSelected} data={spec} />}
            </div>}


        </>
    );

};

export default Spec